import * as React from 'react'
import replace from 'lodash/fp/replace'
import Image from 'next/image'
import Link from 'next/link'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'

import { AdvertiserDisclaimer } from '../'
import { getCurrentMonth, getCurrentYear, nextImageHelper } from '../../utils'
import cn from 'utils/classnames'

export function Header(props: {
  description: [] | string
  headline: []
  icon?: string
  listDescMobShow?: boolean
  loanCategory?: boolean
  mobileSubtitle?: [] | string
}) {
  const varCodeSerializer = ({
    value,
  }: {
    value: { placeholderText: string }
  }) => {
    const replaceMonth = (placeholderText: string) =>
      replace('CurrentMonth', getCurrentMonth(), placeholderText)
    const replaceYear = (placeholderText: string) =>
      replace('CurrentYear', String(getCurrentYear()), placeholderText)
    const addSpace = (placeholderText: string) =>
      placeholderText[0] !== ' ' ? ` ${placeholderText}` : placeholderText

    const finalText = addSpace(replaceYear(replaceMonth(value.placeholderText)))
    return <span className="hidden / sm:inline">{finalText}</span>
  }

  const HeadlineBlock = ({ children }: { children: any }) => (
    <h1 className="c-cate-masthead__header / w-full / relative / mb-1 xl:-mt-1 / font-serif leading-tight text-primary text-3xl lg:text-4xl font-bold">
      {children}
    </h1>
  )

  return (
    <header
      className={cn(
        'c-cate-masthead c-cate-masthead--center / relative / pb-4 pt-3 pl-0',
        {
          'padding-left': !props.loanCategory,
        },
      )}
    >
      <div className="c-cate-masthead__inner / flex flex-col xs:flex-row xs:flex-wrap xs:justify-around / md:flex-col ">
        <div className="c-cate-masthead__disclosure / w-full mb-2 / order-0 text-right">
          <AdvertiserDisclaimer />
        </div>
        <PortableText
          value={props.headline}
          components={{
            block: HeadlineBlock as PortableTextBlockComponent,
            types: {
              varCode: varCodeSerializer,
            },
            marks: {
              underline: ({ children }: { children: any }) => (
                <span className="border-b-4	border-tetriary">{children}</span>
              ),
            },
          }}
        />
        <div
          className={`c-cate-masthead__content ${
            props.listDescMobShow
              ? 't-show-list flex w-full'
              : 'w-full md:flex md:items-center md:justify-start'
          } `}
        >
          {props.icon && (
            <span
              className={`c-cate-masthead__icon-wrap / w-12 h-12 / -mr-1 / ${
                props.listDescMobShow ? 'sm:block' : 'md:block'
              } hidden`}
            >
              <div className="c-cate-masthead__icon w-full">
                <Image
                  width={64}
                  height={64}
                  alt=""
                  src={props.icon}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </div>
            </span>
          )}

          <div className="c-cate-masthead__description / max-w-4xl mb-1 md:mb-0 md:pl-5">
            <p className={`text-slate-600 text-sm`}>
              <Link
                href={`/partners/`}
                target="_blank"
                className="inline-block / text-primary-bright / hover:text-primary-mid hover:underline transition"
              >
                Partner
              </Link>{' '}
              compensation may influence listings but never our reviews. Learn
              more{' '}
              <Link
                href={`/ratings/`}
                target="_blank"
                className="inline-block / text-primary-bright / hover:text-primary-mid hover:underline transition"
              >
                here
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
      <style jsx>{`
        .t-show-list .c-header-list {
          display: block;
        }
        .t-show-list .c-header-list {
          font-size: 12px;
        }
        @media (min-width: 480px) {
          .t-show-list .c-header-list {
            font-size: 13px;
          }
        }
        @media (min-width: 640px) {
          .t-show-list .c-header-list {
            font-size: 14px;
          }
        }
        @media (min-width: 1024px) {
          .padding-left {
            padding-left: 3.25rem;
          }
        }
        @media (min-width: 1151px) {
          .padding-left {
            padding-left: 4.9rem;
          }
        }
      `}</style>
    </header>
  )
}
