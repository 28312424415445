import React from 'react'
import Link from 'next/link'
import { CompareCredit } from '../../../../types/compare-credit'
import {
  getCurrentMonth,
  getCurrentYear,
  useFetchCardsSlug,
} from '../../../utils'
import range from 'lodash/range'
import {
  AddToCompareBtn,
  CardArt,
  StarRatingEditor,
  UserContext,
} from '../../../components'
import some from 'lodash/fp/some'
import attributeSelector from './attributeSelector'

export function BusinessFeatCards({
  popularCards,
}: {
  popularCards: { attribute: string; cards: { slug: string } }[]
}) {
  // I. Load Up Cards
  // Single card category
  const externalId = '80'
  const sortParams = { categoryId: externalId }

  const slugs = popularCards.map(({ cards }) => cards.slug)
  const popularCardsReduced = popularCards.reduce(
    (acc, { cards, attribute }) => {
      acc[cards.slug] = attribute
      return acc
    },
    {} as { [key: string]: string },
  )

  const { cards, isFetching } = useFetchCardsSlug(slugs, sortParams)

  return (
    <div className="c-cards-feat / mb-10 lg:mb-12 / px-4 xs:px-6 md:px-1.5 lg:px-3 xl:px-6 / pt-4 pb-6 sm:pb-8 md:pb-6 xl:pt-6 lg:pb-10 / bg-blue-800 rounded-lg">
      <h3 className="mb-6 lg:mb-8 / font-serif font-bold leading-[120%] text-white text-center text-[27px] xs:text-[32px] sm:text-[36px] ">
        Popular{' '}
        <span className="text-tetriary text-shadow-base">
          Business Credit Cards
        </span>{' '}
        of&nbsp;{getCurrentMonth()}&nbsp;{getCurrentYear()}
      </h3>

      <ul className="c-cards-feat__list / md:grid md:grid-cols-3 md:gap-6 lg:gap-8 xl:gap-12 / max-w-sm md:max-w-none mx-auto / md:px-4">
        {isFetching && (
          <div className="md:col-span-3 md:flex md:flex-row / w-full max-w-sm md:max-w-none mx-auto">
            {slugs.map((_, i) => (
              <div key={i} className="md:w-1/3 md:px-3 lg:px-6">
                <CardTileSkeleton n={1} />
              </div>
            ))}
          </div>
        )}

        {cards &&
          !isFetching &&
          slugs.map((slug) => (
            <li
              key={slug}
              className="c-cards-feat__grid-item / grid grid-cols-3 md:grid-cols-1 md:items-stretch"
            >
              <CardTile
                card={
                  cards.find(
                    (card) => card.slug === slug,
                  ) as CompareCredit.FormattedCard
                }
                attributeToHighlight={popularCardsReduced[slug] as string}
              />
            </li>
          ))}
      </ul>
      <style jsx>
        {`
          .c-cards-feat {
            background-image: url('/static/images/bg-business-popular-cards.jpg');
            background-size: cover;
            background-repeat: no-repeat;
          }
          .c-cards-feat__grid-item {
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            border-bottom: 2px solid rgba(255, 255, 255, 0.3);
          }
          .c-cards-feat__grid-item:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
          }
          @media (min-width: 768px) {
            .c-cards-feat__grid-item {
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: 0;

              justify-items: stretch;
              align-items: stretch;
              grid-auto-rows: 1fr 0.5fr auto;
            }
          }
        `}
      </style>
    </div>
  )
}

function CardTile(props: {
  card: CompareCredit.FormattedCard
  comparisonDisabled?: boolean
  attributeToHighlight:
    | keyof typeof attributeSelector
    | keyof CompareCredit.FormattedCard
}) {
  const { card, attributeToHighlight } = props
  const { comparisonCart, updateComparisonCart } = React.useContext(UserContext)

  const [cardIsInCart, setCardIsInCart] = React.useState(false)

  const handleUpdateCart = (action: 'add' | 'remove') => {
    updateComparisonCart(action, [card])
  }

  React.useEffect(() => {
    setCardIsInCart(
      some(
        (obj: any) => obj.slug === card.slug,
        // @ts-ignore
        comparisonCart,
      ),
    )
  }, [comparisonCart.length, card.name])

  return (
    <>
      <div className="col-span-3 md:col-span-1 / grid grid-cols-5 md:block">
        <div className="col-span-2 md:col-span-5 / md:mb-4 / pr-4 md:pr-0">
          <div className="c-card-art relative / w-full lg:w-3/4 lg:mx-auto">
            <div className="c-card-art__container / relative / max-w-xs / text-center / rounded-sm shadow hover:shadow-lg">
              <Link
                href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
                className="relative flex overflow-hidden rounded-sm shadow z-0"
              >
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-sm md:text-base lg:text-lg">
                  <span className="w-full font-semibold">Learn More</span>
                </span>
                <CardArt
                  _rev={card._rev}
                  cardArt={card.cardArt}
                  imgClasses="relative z-0 w-full h-full object-cover"
                  customCodeSnippets={card.customCodeSnippets}
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  slug={card.slug}
                  externalId={null}
                  categoryId={null}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-span-3 md:col-span-5 md:w-full sm:mb-2">
          <p className="mb-0.5 lg:mb-1 leading-tight lg:text-center">
            <Link
              href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
              className="text-white text-sm xs:text-base lg:text-lg font-semibold text-shadow-base / hover:text-primary-light hover:underline"
            >
              {card.name}
            </Link>
          </p>
          <div className="lg:flex lg:flex-row lg:justify-between lg:items-center">
            <div className="flex justify-start lg:justify-center / py-1 lg:pr-1">
              <StarRatingEditor
                productSlug={card.slug}
                stars={card.expertReviewRating}
                themeLeft={true}
                themeLight={true}
                themeRow={true}
              />
            </div>
            {!props.comparisonDisabled ? (
              <div className="w-full lg:w-auto shrink-0">
                <AddToCompareBtn
                  compact
                  themeLight
                  detailsTop
                  width
                  align="left"
                  inCart={cardIsInCart}
                  updateCart={handleUpdateCart}
                />
              </div>
            ) : null}
            {/* <div className="-mt-1.5 / text-sm text-white">add cart</div> */}
          </div>
        </div>
      </div>
      <div className="c-card-tile__attribute / col-span-3 md:col-span-1 / mb-2 / pt-0.5 sm:pt-1 md:py-2 xl:py-3 / text-sm xl:text-base text-white text-shadow-base">
        <p className="mb-0.5 font-bold">
          {attributeSelector[attributeToHighlight]}
        </p>
        <div>{card[attributeToHighlight]}</div>
      </div>
      <div className="c-cards-feat__grid-item-last / col-span-3 md:col-span-1 / md:flex md:items-end / w-full mt-1 xs:mt-2">
        <Link
          href={`/credit-cards/${card.issuer.slug.current}/${card.slug}`}
          className="c-btn c-btn--secondary / max-w-none / py-1.5 lg:py-2 / text-base shadow-lg border-0 border-t border-sky-400 /
      hover:shadow-xl hover:bg-primary-bright-light hover:border-sky-300"
        >
          Learn More
        </Link>
      </div>
      <style jsx>{`
        @media (min-width: 768px) {
          .c-card-tile__attribute {
            border-top: 1px solid rgba(255, 255, 255, 0.3);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          }
        }
      `}</style>
    </>
  )
}

interface Props {
  /**
   * Number of loading skeleton's to render.
   */
  n: number
  theme?: string
}
function CardTileSkeleton({ n }: Props) {
  return (
    <>
      {range(n).map((idx) => (
        <div key={idx} className="relative z-1 shrink-0 w-full my-1">
          <div className="py-4 border-b border-slate-200 md:border-0">
            <div className="flex flex-row flex-wrap items-start md:flex-col">
              <div className="c-content-placeholder-bg / shrink-0 / w-2/5 md:w-full md:max-w-[14rem] h-14 md:h-32 / mx-auto mb-2 / bg-gray-100 rounded-sm"></div>
              <div className="flex flex-col md:items-center md:justify-center / w-3/5 / mx-auto / pl-4 md:pl-0">
                <div className="sm:w-full">
                  <div className="c-content-placeholder-bg / w-10/12 md:w-full h-6 / mb-1.5 / bg-gray-100 rounded-sm"></div>
                  <div className="c-content-placeholder-bg / w-28 md:w-1/2 h-4 / mb-1.5 md:mx-auto / bg-gray-100 rounded-sm"></div>
                </div>
              </div>
              <div className="c-content-placeholder-bg / shrink-0 / w-full h-14 / mb-2 / bg-gray-100 rounded-sm"></div>
              <div className="c-content-placeholder-bg / shrink-0 / w-full h-8 / bg-gray-100 rounded-sm"></div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
