import React from 'react'
import cn from 'utils/classnames'
import { useStatsigClient } from '@statsig/react-bindings'
import { isMobileOnly } from 'react-device-detect'
import Image from 'next/image'

import { nextImageHelper } from '../../utils/next-image-css-helper'
import { clickCategoryTag } from '../../clients/segment'
import { Tag } from '../filters/by-tag'

interface Props {
  render: (selectedTag: string) => React.ReactNode
  tags: Tag[]
}

export function CardCarouselFilter(props: Props) {
  const { tags } = props
  const [selectedTag, setSelectedTag] = React.useState(tags[0].id)
  const [dropdownActive, setDropdownActive] = React.useState(false)
  const { logEvent } = useStatsigClient()

  const activeTag = tags.find((tag) => tag.id === selectedTag) || tags[0]

  React.useEffect(() => {
    setSelectedTag(tags[0].id)
  }, [JSON.stringify(tags)])

  const handleFilter = React.useCallback(
    (subcategory: string) => {
      logEvent('product_filter_selected', subcategory)
      setSelectedTag(subcategory)
      clickCategoryTag(subcategory, `${subcategory} Credit Cards`)
    },
    [isMobileOnly],
  )

  const handleFilterMobile = React.useCallback(
    (subcategory: string) => {
      logEvent('product_filter_selected', subcategory)
      setSelectedTag(subcategory)
      clickCategoryTag(subcategory, `${subcategory} Credit Cards`)
      setDropdownActive(false)
    },
    [isMobileOnly],
  )

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const category = params.get('category')
    if (
      !!category &&
      category.length > 0 &&
      !!tags.find(({ id }) => id === category)
    ) {
      setSelectedTag(category)
    }
  }, [])

  return (
    <div className="c-card-carousel__filter">
      <div className="mb-2 lg:mb-6">
        <div className="md:hidden">
          <div className="relative / flex justify-center items-center">
            <button
              className="relative / flex items-center justify-center / w-[19rem] / mx-auto / py-1 px-6 / bg-white rounded-full border border-transparent shadow /
            text-center text text-primary font-semibold / transition-all / hover:text-primary-mid hover:shadow-lg hover:border-primary-bright hover:bg-primary-light-bg"
              onClick={() => setDropdownActive(!dropdownActive)}
            >
              <span className={`inline-block / w-8 / mr-2.5`}>
                <Image
                  alt="icon"
                  src={activeTag.icon}
                  role="presentation"
                  aria-hidden={true}
                  width={16}
                  height={16}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </span>
              {activeTag.label}
              <span
                className={`inline-block / w-4 / ml-3 -mr-3 / transition-all my-auto opacity-40 ${
                  dropdownActive ? 'rotate-180' : ''
                }`}
              >
                <Image
                  alt="chevron"
                  src="/static/icons/icon-chevron-d-black.svg"
                  role="presentation"
                  aria-hidden={true}
                  width={16}
                  height={16}
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </span>
            </button>
            <div
              className={`c-dropdown__menu / absolute z-30 top-full / w-[19rem] / mx-auto ${
                dropdownActive ? '' : 'is-closed'
              }`}
            >
              <ul className="c-dropdown__list / flex flex-col / shadow-md / rounded-lg bg-white px-1 py-2 / xs:px-1 xs:py-3 / text-slate-700">
                {tags.map((tag) => (
                  <li className="mb-1 px-1" key={tag.id}>
                    <button
                      className={cn('flex items-center / w-full / px-4 py-1', {
                        'font-semibold text-primary bg-primary-light-bg':
                          selectedTag === tag.id,
                        'hover:font-semibold hover:bg-primary-light-bg hover:text-primary-mid':
                          selectedTag !== tag.id,
                      })}
                      onClick={() => handleFilterMobile(tag.id)}
                    >
                      <span className={`inline-block / w-8 / mr-3`}>
                        <Image
                          alt="icon"
                          src={tag.icon}
                          role="presentation"
                          aria-hidden={true}
                          width={16}
                          height={16}
                          style={nextImageHelper.responsive}
                          sizes="100vw"
                        />
                      </span>
                      {tag.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="hidden md:block">
          <ul className="flex flex-row justify-between / lg:max-w-[60rem] / mx-auto / p-1 / text-sm lg:text-base text-slate-700 / border border-slate-100 shadow-md rounded-full bg-white">
            {tags.map((tag) => (
              <li key={tag.id}>
                <button
                  className={cn(
                    'flex items-center / w-full / px-2 lg:pl-4 lg:pr-6 py-1.5 rounded-full',
                    {
                      'font-semibold text-primary bg-primary-light-bg':
                        selectedTag === tag.id,
                      'font-semibold hover:bg-primary-light-bg hover:text-primary-mid transition-all':
                        selectedTag !== tag.id,
                    },
                  )}
                  onClick={() => handleFilter(tag.id)}
                >
                  <span className={`inline-block / w-6 lg:w-8 / mr-2`}>
                    <Image
                      alt="icon"
                      src={tag.icon}
                      role="presentation"
                      aria-hidden={true}
                      width={16}
                      height={16}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                    />
                  </span>
                  {tag.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {props.render(selectedTag)}

      <style jsx>{`
        .c-dropdown__arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1.25rem;
        }
        .c-dropdown__menu {
          top: 100%;
          left: 0;
          right: 0;
          max-height: 60rem;
          opacity: 1;
          overflow: visible;
          transition: all 0.25s ease-in-out;
        }
        .c-dropdown__menu.is-closed {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
        }
      `}</style>
    </div>
  )
}
