import pageViews from '../../fetch-data/data/page-views.json'
import productViews from '../../fetch-data/data/product-views.json'

interface PageViews {
  [key: string]: number
}
export const getViewsBySlug = () => {
  // www.comparecredit.com/credit-cards/tips/best-in-class-citi-dc-bl -> tips/best-in-class-citi-dc-bl/
  // www.comparecredit.com/credit-cards/tips/best-in-class-citi-dc-bl/double-cash/ -> tips/best-in-class-citi-dc-bl/double-cash/
  const tipSlug = window.location.pathname.replace(
    /\/credit-cards\/([^/]+)\/?([^/]+)?/,
    '$1/$2',
  )
  return (pageViews as PageViews)[tipSlug]
}

export const getViewBySku = (sku: string) => {
  return (productViews as PageViews)[sku]
}
