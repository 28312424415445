import React from 'react'
import { differenceInDays, format, isFuture, sub as subtract } from 'date-fns'
import propOr from 'lodash/fp/propOr'
import {
  PortableText,
  PortableTextBlockComponent,
  PortableTextMarkComponent,
} from '@portabletext/react'

import { AdvertiserDisclaimer, ApplyNowLink, link } from '../'
import { CompareCredit } from '../../../types/compare-credit'
import { supify } from '../../utils'
import { RatesAndFeesLink } from '../sanity-serializers/ratesAndFeesLink'

export function Header(props: {
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  externalId: string
  orderId: string
}) {
  const { card, externalId, orderId } = props

  const getDisplayDate = () => {
    if (card.date) {
      // Casts card.date from Sanity into Date object
      const splitDate = card.date.split('-')
      const introDate = new Date(
        Number(splitDate[0]),
        Number(splitDate[1]) - 1,
        Number(splitDate[2]),
      )
      const today = new Date()
      return Math.abs(differenceInDays(today, introDate)) > 14
        ? subtract(today, { days: 14 })
        : isFuture(introDate)
        ? today
        : introDate
    }
  }

  const displayDate = getDisplayDate() as Date

  const IntroRenderer = ({ children }: { children: any }) => (
    <p className="mb-5 sm:mb-2">{children}</p>
  )

  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return React.createElement('span', {
      dangerouslySetInnerHTML: {
        __html: supify(
          propOr('', attrProps.value.attribute, card)?.toString() ?? '',
        ),
      },
    })
  }

  const header = `${supify(card.name)} Review`

  return (
    <div className="relative z-10 / c-header-detail / mx-auto mt-8 max-w-4xl">
      <h1
        className="mb-5 sm:mb-2 / font-bold font-serif text-3xl md:text-4xl lg:text-4xl text-center text-primary leading-tight"
        dangerouslySetInnerHTML={{
          __html: header,
        }}
      />
      <div className="flex flex-col justify-between sm:flex-row sm:items-center / w-full / mb-4 sm:mb-5 pb-3 sm:pb-1 / border-b-4 border-gray-200">
        <p className="w-full sm:w-auto / mb-3 sm:mb-0 / text-xs sm:text-fs13 text-gray-700">
          {card.author && (
            <b className="font-semibold text-gray-700">By {card.author.name}</b>
          )}
          {card.date && (
            <span className="inline-block ml-1 italic">
              , {format(displayDate, 'MMMM d, yyyy')}
            </span>
          )}
        </p>
        <div className="flex items-start justify-start / w-full sm:w-auto">
          <div className="-mt-1 / sm:hidden">
            <AdvertiserDisclaimer alignPopup="t-disclosure-right" />
          </div>
        </div>
      </div>
      {card.introContent.length > 0 && (
        <div className="text-gray-700">
          <PortableText
            value={card.introContent as any}
            components={{
              block: {
                normal: IntroRenderer as PortableTextBlockComponent,
              },
              marks: {
                applyNowLink: ApplyNowLink(
                  externalId,
                  orderId,
                ) as PortableTextMarkComponent,
                link: link as PortableTextMarkComponent,
                ratesAndFeesLink: RatesAndFeesLink as PortableTextMarkComponent,
              },
              types: {
                cardAttribute: AttributeRenderer,
              },
            }}
          />
        </div>
      )}
    </div>
  )
}
