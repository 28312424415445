import * as React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import Image from 'next/image'
import Router from 'next/router'
import { useStatsigClient, useFeatureGate } from '@statsig/react-bindings'
import { useExperiment } from 'utils/use-experiment-wrapper'

import { v4 as uuid } from 'uuid'
import { useFetchCardsSlug } from '../../utils'
import cn from 'utils/classnames'

import {
  CardCart,
  CookieDisclaimer,
  CreditModal,
  Footer,
  FooterMin,
  NavBar,
  SortSelect,
  UserContext,
  ModalLeaveBehindContext,
  ModalLeaveBehindExtension,
  ModalLeaveBehindCard,
} from '../'
import { CompareCredit } from '../../../types'
import { getUrl } from '../../utils/getURL'
// 2023.5: EXP-135 Leave Behind
import MobileNav from '../navigation/mobile-nav'
import {
  modalExtensionClosed,
  modalExtensionOpened,
} from '../../clients/segment'
import MultistepBusinessForm from '../business-modal'

let hasPageLoaded = false

interface Props {
  alert?: {
    active: boolean
    message: any[] | undefined
  }
  banner?: React.ReactNode
  children: React.ReactNode
  comparisonDisabled?: boolean
  disclaimerAlignment?: 'left' | 'right' | 'top' | string
  extraClasses?: string
  footerType?: string
  hideAsSeenIn?: boolean
  hideBBBIcon?: boolean
  lightTheme?: boolean
  mixTheme?: boolean
  mobileFooterTileHeight?: number
  navNone?: boolean
  navType?: string
  onHome?: boolean
  showBackButton?: boolean
  hideBackButtonInComparisionPage?: boolean
  upsellBanner?: CompareCredit.NavUpsellBanner
  modalLeaveBehindCard?: CompareCredit.ModalLeaveBehindCard
  isModalLeaveBehindCard?: boolean
  slug?: string
}

function Navigation(props: {
  alert: {
    height: number
    setHeight: React.Dispatch<React.SetStateAction<number>>
    active: boolean
    show: boolean
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
    message: any[] | undefined
  }
  banner: React.ReactNode | undefined
  disclaimerAlignment?: 'left' | 'right' | 'top' | string
  hideAsSeenIn?: boolean
  hideBBBIcon?: boolean
  lightTheme?: boolean
  mixTheme?: boolean
  navNone?: boolean
  navType?: string
  onHome: boolean
  setShowAlertUpsell: React.Dispatch<React.SetStateAction<boolean>>
  upsellBanner?: CompareCredit.NavUpsellBanner
}) {
  const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false)
  const [selectedSubMenu, setSelectedSubMenu] = React.useState<string | null>(
    null,
  )

  return (
    <>
      <NavBar
        alert={props.alert}
        banner={props.banner}
        disclaimerAlignment={props.disclaimerAlignment}
        hideAsSeenIn={props.hideAsSeenIn}
        hideBBBIcon={props.hideBBBIcon}
        lightTheme={props.lightTheme}
        mixTheme={props.mixTheme}
        onHome={props.onHome}
        setShowMobileNav={setShowMobileNav}
        showMobileNav={showMobileNav}
        setSelectedSubMenu={setSelectedSubMenu}
        navType={props.navType}
        setShowAlertUpsell={props.setShowAlertUpsell}
        upsellBanner={props.upsellBanner}
      />
      <MobileNav
        alert={{
          height: props.alert.height,
          show: props.alert.show,
        }}
        show={showMobileNav}
        selectedSubMenu={selectedSubMenu}
        setSelectedSubMenu={setSelectedSubMenu}
        setShowMobileNav={setShowMobileNav}
      />
    </>
  )
}

export function LayoutMain(props: Props) {
  const {
    alert = { active: false, message: undefined },
    disclaimerAlignment,
    upsellBanner,
    isModalLeaveBehindCard,
    modalLeaveBehindCard,
  } = props

  const [showAlert, setShowAlert] = React.useState(false)
  const [alertHeight, setAlertHeight] = React.useState(0)
  const [showAlertUpsell, setShowAlertUpsell] = React.useState(false)
  const { pathname, query } = getUrl()
  const { logEvent } = useStatsigClient()

  const { comparisonCart } = React.useContext(UserContext)

  // show/hide alert when alert.active changes
  // this should force a change every time a new category is selected
  React.useEffect(() => setShowAlert(alert.active), [alert.active])
  React.useEffect(() => {
    // Allows us to ensure we only log the page_event one time
    // LayoutMain currently re-renders so a typical [] useEffect
    // does not work by itself.
    if (!hasPageLoaded) {
      hasPageLoaded = true
      logEvent('page_loaded', pathname || undefined, query || undefined)
    }
  }, [])

  const { value: showSortSelect } = useFeatureGate('select_sort')

  // Leave behind modal
  const {
    openModalLeaveBehindExtension,
    setOpenModalLeaveBehindExtension,
    shouldOpenModalLeaveBehindCapOneShopping,
    setIsModalLeaveBehindCard,
  } = React.useContext(ModalLeaveBehindContext)

  const onCloseModalLeaveBehindExtension = React.useCallback(() => {
    setOpenModalLeaveBehindExtension(false)
    modalExtensionClosed()
    logEvent('modal_leave_behind_extension_closed')
  }, [setOpenModalLeaveBehindExtension])

  React.useEffect(() => {
    if (openModalLeaveBehindExtension) {
      modalExtensionOpened()
    }
  }, [openModalLeaveBehindExtension])

  /* ========== LBM - CapOne ========== */
  const {
    modalLeaveBehindOrderId,
    openModalLeaveBehind,
    setModalLeaveBehindId,
    setModalLeaveBehindSlug,
    setModalLeaveBehindOrderId,
    onCloseModalLeaveBehind,
  } = React.useContext(ModalLeaveBehindContext)

  const externalId = '00'
  const categoryId = '00'
  const sortParams = { categoryId: externalId }
  const slug = ['cap-one-shopping']
  const { cards } = useFetchCardsSlug(slug, sortParams)
  const capOneShopping = cards[0] as CompareCredit.FormattedCard

  /* ========== EXP 341 && 341 - HIDE NAVBAR ========== */
  const { experiment: configExp340, isLoading: isLoadingExp340 } =
    useExperiment('exp_340_hide_nav_bar_best_li')
  const variationExp340 = configExp340?.value?.name || 'control'

  const { experiment: configExp341, isLoading: isLoadingExp341 } =
    useExperiment('exp_341_hide_nav_bar_p0i')
  const variationExp341 = configExp341?.value?.name || 'control'

  React.useEffect(() => {
    if (
      capOneShopping &&
      shouldOpenModalLeaveBehindCapOneShopping &&
      !isModalLeaveBehindCard
    ) {
      setModalLeaveBehindId(uuid())
      setModalLeaveBehindOrderId(uuid())
      setModalLeaveBehindSlug(capOneShopping.slug)
      setIsModalLeaveBehindCard(true)
    }
  }, [
    capOneShopping,
    shouldOpenModalLeaveBehindCapOneShopping,
    isModalLeaveBehindCard,
  ])

  /* ===== 2024.09: EXP-325 Venture Banner Upsell on Travel Category Page ===== */
  const { experiment: configExp325, isLoading: isLoadingExp325 } =
    useExperiment('exp_325_travel_venture_banner')
  const variationExp325 = configExp325?.value?.name || 'control'

  /* ===== 2024.11: EXP-161 Nav Bar ===== */
  const { experiment: configExp161, isLoading: isLoadingExp161 } =
    useExperiment('exp_161_hide_nav_bar')
  const variationExp161 = configExp161?.value?.name || 'control'

  /* ===== 2024.12: EXP-345 Layout Update ===== */
  const { experiment: configExp337, isLoading: isLoadingExp337 } =
    useExperiment('exp_337_citi_dc_social_reformat')
  const variationExp337 = configExp337?.value?.name || 'control'

  return (
    <>
      <div
        id="layout-main"
        className={cn(
          'c-layout-main flex flex-col min-h-screen justify-between ',
          props.extraClasses,
          {
            'c-layout-main--324-control':
              !isLoadingExp325 && variationExp325 === 'control',
            'c-layout-main--324-v1':
              !isLoadingExp325 &&
              variationExp325 === 'v1-hide-travel-venture-banner',
            'c-navbar--161-v2':
              (!isLoadingExp161 && variationExp161 === 'v2_navbar_hide') ||
              (!isLoadingExp340 && variationExp340 === 'v1_navbar_hide') ||
              (!isLoadingExp341 && variationExp341 === 'v1_navbar_hide'),
            'c-layout--337-v1':
              !isLoadingExp337 && variationExp337 === 'v1-layout-update',
          },
        )}
      >
        <a
          className="c-skip-link / sr-only focus:not-sr-only"
          href="#main-content"
        >
          Skip to main content
        </a>
        {props.navNone ? (
          ''
        ) : (
          <Navigation
            alert={{
              active: alert.active,
              height: alertHeight,
              message: alert.message,
              setHeight: setAlertHeight,
              setShowAlert: setShowAlert,
              show: showAlert,
            }}
            banner={props.banner}
            disclaimerAlignment={disclaimerAlignment}
            hideAsSeenIn={props.hideAsSeenIn}
            hideBBBIcon={props.hideBBBIcon}
            lightTheme={props.lightTheme}
            mixTheme={props.mixTheme}
            navType={props.navType}
            onHome={props.onHome || false}
            setShowAlertUpsell={setShowAlertUpsell}
            upsellBanner={upsellBanner}
          />
        )}
        {showSortSelect && <SortSelect />}
        <div
          className={`c-main__container transition-25s ${
            !isLoadingExp325 && showAlertUpsell
              ? 'pt-40 xs:pt-36 sm:pt-[7.5rem] lg:pt-[8.5rem]'
              : `pt-16 lg:pt-20`
          } ${
            disclaimerAlignment == 'top' && `c-main__container--disclaimer-top`
          }`}
          style={{
            marginTop: showAlert ? alertHeight : 0,
          }}
        >
          <main
            id="main-content"
            className={`c-main / container-fluid text-dark ${
              showSortSelect && 'c-sort-select-desktop'
            }`}
          >
            {props.children}
          </main>{' '}
        </div>
        <CookieDisclaimer />
        {props.footerType === 'minimal' ? <FooterMin /> : <Footer />}
        <CreditModal />
        {props.showBackButton && !props.hideBackButtonInComparisionPage && (
          <div className="c-cart is-on / flex w-full / fixed z-30 bottom-0 left-0 / sm:ml-21 sm:mb-21 / transition-all--25">
            <div className="c-cart__row / relative / flex content-start items-center justify-start / w-full / px-4 py-2  lg:px-4 / bg-white shadow-2xl">
              <button
                className="c-cart__btn-reset flex items-center / w-16 h-10 pr-4 / text-gray-600 text-sm sm:text-base / hover:text-primary-bright focus:outline-none / transition-all"
                onClick={() => Router.back()}
              >
                <div className="shrink-0 inline-block mr-2 / opacity-50 / w-4 h-4">
                  <Image
                    alt="chevron left"
                    src="/static/icons/icon-chevron-l-black.svg"
                    height={16}
                    width={16}
                  />
                </div>
                Back
              </button>
            </div>
          </div>
        )}
      </div>

      <MultistepBusinessForm />

      {shouldOpenModalLeaveBehindCapOneShopping ? (
        <div className="relative z-80">
          {modalLeaveBehindOrderId && (
            <ModalLeaveBehindCard
              modalLeaveBehindCardProduct={capOneShopping}
              modalLeaveBehindCardTitle={''}
              categoryId={categoryId}
              externalId={externalId}
              orderId={modalLeaveBehindOrderId}
              cardOrderIds={null}
              referencedCards={null}
              open={openModalLeaveBehind}
              onClose={onCloseModalLeaveBehind}
            />
          )}
        </div>
      ) : isModalLeaveBehindCard ? (
        <div className="relative z-80">
          {modalLeaveBehindOrderId &&
            modalLeaveBehindCard &&
            modalLeaveBehindCard.referencedCards &&
            modalLeaveBehindCard.cardOrderIds && (
              <ModalLeaveBehindCard
                modalLeaveBehindCardProduct={
                  modalLeaveBehindCard.referencedCards[
                    modalLeaveBehindCard.modalLeaveBehindCardProduct.slug
                  ] as CompareCredit.FormattedCard
                }
                modalLeaveBehindCardTitle={
                  modalLeaveBehindCard.modalLeaveBehindCardTitle
                }
                modalLeaveBehindCardContent={
                  modalLeaveBehindCard.modalLeaveBehindCardContent
                }
                modalLeaveBehindCardDeclineText={
                  modalLeaveBehindCard.modalLeaveBehindCardDeclineText
                }
                categoryId={categoryId}
                externalId={externalId}
                orderId={modalLeaveBehindOrderId}
                cardOrderIds={modalLeaveBehindCard.cardOrderIds}
                referencedCards={modalLeaveBehindCard.referencedCards}
                open={openModalLeaveBehind}
                onClose={onCloseModalLeaveBehind}
              />
            )}
        </div>
      ) : (
        ''
      )}

      <ModalLeaveBehindExtension
        onClose={onCloseModalLeaveBehindExtension}
        open={openModalLeaveBehindExtension}
      />

      {!props.showBackButton ? (
        <CardCart />
      ) : (
        <>
          {!props.comparisonDisabled && !isEmpty(comparisonCart) && (
            <CardCart />
          )}
        </>
      )}

      <style jsx>{`
        /* T-DISCLAIMER-TOP: Theme used to display the advertiser disclosure
        at the top of the tips pages
        ======================================================= */
        .c-main__container--disclaimer-top {
          padding-top: 5.5rem;
        }
        @media (min-width: 1024px) {
          .c-main__container--disclaimer-top {
            padding-top: 6.5rem;
          }
        }
      `}</style>
    </>
  )
}
