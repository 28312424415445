import React, { useMemo } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { clickRatesAndFees } from '../../clients/segment'

import {
  AdvertiserDisclaimer,
  CardArt,
  CardPanel,
  LinkToRedirect,
  Modal,
  SecureSite,
  StarRatingEditor,
} from '../../components'
import { getCurrentMonth, getCurrentYear } from '../../utils'
import { nextImageHelper, useFetchCardsSlug } from '../../utils'
import { CompareCredit } from '../../../types'
import { CardCarouselFilter } from './filter'
import { TagCategories } from '../credit-cards/categoryTags'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'
import cn from '../../utils/classnames'

const cardCarouselTags: TagCategories = {
  best: {
    code: '108',
    icon: '/static/icons/categories/top-cc.svg',
    id: 'best',
    label: 'Top Cards',
  },
  'balance-transfer': {
    code: '13',
    icon: '/static/icons/categories/balance-transfer.svg',
    id: 'balance-transfer',
    label: 'Balance Transfer',
  },
  'low-interest': {
    code: '12',
    icon: '/static/icons/categories/low-interest.svg',
    id: 'low-interest',
    label: 'Low Interest',
  },
  rewards: {
    code: '11',
    icon: '/static/icons/categories/rewards.svg',
    id: 'rewards',
    label: 'Rewards',
  },
  'cash-back': {
    code: '10',
    icon: '/static/icons/categories/cash-back.svg',
    id: 'cash-back',
    label: 'Cash Back',
  },
}

export function CardCategoryCarousel(props: {
  categories: CompareCredit.BestPageCards
}) {
  return (
    <div
      className="c-card-carousel__container / w-full-vw / / -mb-4 md:-mb-8 / -mt-16 lg:-mt-20 / pt-14
    / relative z-10 / bg-[url('/static/images/bg-blue-gradient-waves-light-1.jpg')] lg:bg-[url('/static/images/bg-blue-gradient-waves-light-2.jpg')]
    / bg-[length:auto_100%] bg-[bottom_12rem_center] lg:bg-[bottom_8rem_center] bg-no-repeat
    "
    >
      <div className="c-card-carousel / container-fluid relative z-3 / md:flex md:flex-row max-w-[68rem] xl:max-w-[68rem] min-h-[60rem]  sm:min-h-[58rem] lg:min-h-[62rem] xl:min-h-[65rem] / lg:px-8 xl:px-0">
        <section className="c-card-carousel__section / w-full">
          <header className="mb-4 lg:mb-6 / pt-6 xl:pt-10">
            <div className="flex justify-end / mb-3 sm:mb-4">
              <AdvertiserDisclaimer
                alignPopup="t-disclosure-right"
                disclosureTextAlign="left"
                fontSizeBase={false}
              />
            </div>
            <h1 className="mb-2 / font-serif font-bold / leading-[1.1] text-primary text-center text-3xl xs:text-4xl lg:text-[40px] xl:text-[44px]">
              Top Credit Cards for {getCurrentMonth()}&nbsp;{getCurrentYear()}
            </h1>
            <p className="flex items-center justify-center / max-w-[24rem] sm:max-w-[34rem] lg:max-w-[42rem] / mx-auto  / px-6 sm:px-0 / text-gray-700 text-center lg:text-lg">
              <span className="z-1 / inline-block w-full border-b-2 border-tetriary"></span>
              <span
                className={`c-featured-text shrink-0 / relative z-2 / inline-block px-2 lg:px-4 mx-auto`}
              >
                Offers from Our Partners
              </span>
              <span className="z-1 / inline-block w-full border-b-2 border-tetriary"></span>
            </p>
          </header>
          <CardCarouselFilter
            tags={Object.values(cardCarouselTags)}
            render={(selectedTag: string) => {
              const cardSlugs = props.categories[selectedTag]?.cards ?? []
              const sortParams = {
                categoryId: cardCarouselTags[selectedTag]?.code,
              }
              const { cards, isFetching } = useFetchCardsSlug(
                cardSlugs,
                sortParams,
              )
              const cardsAsFormatted = cards as CompareCredit.FormattedCard[]

              const maxCardNameLength = useMemo(() => {
                if (cards.length && cards.length >= 3) {
                  return Math.max(
                    cards[0].name.length,
                    cards[1].name.length,
                    cards[2].name.length,
                  )
                }
                return 0
              }, [cards])

              const customBadgePresent = useMemo(() => {
                if (!cardsAsFormatted || !cardsAsFormatted.length) return false
                return (
                  cardsAsFormatted[0].customBadge ||
                  cardsAsFormatted[1].customBadge ||
                  cardsAsFormatted[2].customBadge
                )
              }, [cardsAsFormatted])

              return (
                <>
                  <div className="c-card-carousel__results / mb-4">
                    <p className="mb-2 / text-sm text-slate-600 text-center / md:hidden">
                      <b>Viewing 3 cards</b>{' '}
                      <span className="inline-block / ml-1.5 / font-normal text-xs text-gray-600 tracking-wider uppercase">
                        (scroll right to view)
                      </span>
                    </p>
                    <div className="c-card-carousel__results-inner / md:static / md:flex md:justify-center md:items-center / w-full-vw md:w-full / md:mx-0 / px-4 md:px-0 pb-4 / overflow-scroll md:overflow-visible">
                      <div className="flex items-stretch / w-[680px] md:w-full / mx-auto">
                        {!isFetching && cards.length > 0 ? (
                          <ul
                            className={`flex flex-row ${
                              customBadgePresent
                                ? 'pt-11 md:pt-14 lg:pt-12'
                                : 'pt-5 md:pt-8'
                            }`}
                          >
                            <li className="flex items-stretch / shrink-0 / w-[14.5rem] md:w-1/3 / mr-4 md:mr-0 / md:pr-2 lg:pr-4 xl:pr-6">
                              <CardTile
                                maxCardNameLength={maxCardNameLength}
                                card={cards[0] as CompareCredit.FormattedCard}
                                tagId={cardCarouselTags[selectedTag].id}
                              />
                            </li>
                            <li className="flex items-stretch / shrink-0 / w-[14.5rem] md:w-1/3 / mr-4 md:mr-0 / md:px-1 lg:px-2 xl:px-3">
                              <CardTile
                                maxCardNameLength={maxCardNameLength}
                                card={cards[1] as CompareCredit.FormattedCard}
                                tagId={cardCarouselTags[selectedTag].id}
                              />
                            </li>
                            <li className="flex items-stretch / shrink-0 / w-[14.5rem] md:w-1/3 / mr-4 md:mr-0 / md:pl-2 lg:pl-4 xl:pl-6">
                              <CardTile
                                maxCardNameLength={maxCardNameLength}
                                card={cards[2] as CompareCredit.FormattedCard}
                                tagId={cardCarouselTags[selectedTag].id}
                              />
                            </li>
                          </ul>
                        ) : (
                          <div className="flex flex-row mx-auto md:w-full">
                            <div className="flex items-stretch / shrink-0 / w-[14.5rem] md:w-1/3 / mr-4 md:mr-0 / md:pr-4 lg:pr-4 xl:pr-6">
                              <SkeletonCard />
                            </div>
                            <div className="flex items-stretch / shrink-0 / w-[14.5rem] md:w-1/3 / mr-4 md:mr-0 / md:px-2 lg:pr-4 xl:pr-6">
                              <SkeletonCard />
                            </div>
                            <div className="flex items-stretch / shrink-0 / w-[14.5rem] md:w-1/3 / mr-4 md:mr-0 / md:pl-4 lg:pr-4 xl:pr-6">
                              <SkeletonCard />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <footer className="flex items-center justify-end">
                    <div>
                      <Link
                        href={`/credit-cards/best/${cardCarouselTags[selectedTag]?.id}/`}
                        className="relative / flex items-center / text-right leading-tight text-xs xs:text-sm text-primary-bright / hover:text-primary-mid hover:underline / transition"
                      >
                        <span>
                          {`View More ${cardCarouselTags[selectedTag]?.label}${
                            cardCarouselTags[selectedTag].label !== 'Top Cards'
                              ? ' Cards'
                              : ''
                          }
                          `}
                        </span>{' '}
                        <span className={`inline-block / w-4 / ml-1`}>
                          <Image
                            alt="icon"
                            src="/static/icons/icon-chevron-r-primary-bright.svg"
                            role="presentation"
                            aria-hidden={true}
                            width={16}
                            height={16}
                            style={nextImageHelper.responsive}
                            sizes="100vw"
                          />
                        </span>
                      </Link>
                    </div>
                  </footer>
                </>
              )
            }}
          />
        </section>
      </div>
      <div
        className="c-hero__bot / absolute bottom-0 z-2 / -mb-[1px] / bg-no-repeat / w-full overflow-hidden"
        role="presentation"
      >
        <img
          src="./static/images/bg-bot-mask-white-sm.png"
          className="c-hero__bg-mask / w-full h-[3rem] / -mb-[1px] / md:hidden"
          alt="mask"
          aria-hidden={true}
        />
        <img
          src="./static/images/bg-bot-mask-white-lg.png"
          className="c-hero__bg-mask / w-full h-[3rem] / lg:max-height-[4rem] / -mb-[1px] / hidden md:block"
          alt="mask"
          aria-hidden={true}
        />
      </div>
    </div>
  )
}

function CardTile(props: {
  card: CompareCredit.FormattedCard
  tagId: string
  maxCardNameLength: number
}) {
  const { card } = props
  const [modalOpen, setModalOpen] = React.useState(false)

  const BlockRenderer = ({ children }: { children: any }) => (
    <span>{children}</span>
  )
  function AttributeRenderer<K extends keyof CompareCredit.Entity>(attrProps: {
    value: { attribute: K }
  }) {
    return React.createElement(
      'span',
      {},
      props.card[attrProps.value.attribute] as React.ReactNode,
    )
  }

  const categoryId = props.tagId ?? '00'

  return (
    <div
      className={`relative / flex flex-col items-stretch justify-start / px-4 xl:px-8 pb-10 / bg-white shadow-lg rounded`}
    >
      <div className="-mt-4 lg:mb-2">
        {card.customBadge && (
          <div className="absolute left-0 right-0 / w-full / -mt-[1.65rem] / text-sm font-bold text-primary uppercase text-center">
            <span className="c-ribbon c-ribbon--slimmer relative inline-block / w-auto / mx-auto px-2 lg:px-4 / bg-tetriary">
              <span className="inline-block pt-[1px] leading-snug text-fs12 lg:text-fs13 overflow-hidden">
                {card.customBadge}
              </span>
            </span>
          </div>
        )}
        <div className="c-card-art / w-28 md:w-32 lg:w-40 xl:w-48 / text-center / flex items-center justify-center / mx-auto mb-1 md:mb-1.5 xl:mb-3">
          <div className="relative / w-full">
            <LinkToRedirect
              externalId={'1'}
              linkParams={card.applyNowLinkParameters}
              orderId={'1'}
              query={undefined}
              slug={card.slug}
            >
              <div className="relative flex rounded-sm overflow-hidden">
                <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-fs13 lg:text-base">
                  <span className="c-card-art__icon / w-full">
                    <span className="inline-block w-6 lg:w-7">
                      <Image
                        height={32}
                        width={32}
                        alt="icon lock"
                        src="/static/icons/icon-lock-round-w.svg"
                        aria-hidden={true}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                      />
                    </span>
                  </span>
                  <span className="w-full font-semibold">Apply Now</span>
                </span>

                <CardArt
                  cardArt={card.cardArt}
                  imgClasses="relative / z-0 / w-full h-full object-cover top-0"
                  customCodeSnippets={card.customCodeSnippets}
                  issuer={card.issuer.slug.current}
                  name={card.name}
                  orderId={'1'}
                  slug={card.slug}
                  type={card._type}
                  categoryId={'1'}
                  externalId={'1'}
                  _rev={null}
                />
              </div>
            </LinkToRedirect>
          </div>
        </div>
        <div>
          <p
            className={cn(
              'flex items-start justify-center / lg:mb-1 / text-center leading-tight',
              {
                'min-h-[2.75em]': props.maxCardNameLength > 25,
                'xl:min-h-[auto]': props.maxCardNameLength < 30,
              },
            )}
          >
            <LinkToRedirect
              externalId={'1'}
              linkParams={card.applyNowLinkParameters}
              orderId={'1'}
              query={undefined}
              slug={card.slug}
            >
              <span className="text-sm lg:text-lg font-bold text-primary-mid / hover:text-primary-bright cursor-pointer / transition-all">
                {card.name}
              </span>
            </LinkToRedirect>
          </p>
          <div className="lg:mb-1 / text-center scale-90 lg:scale-100">
            <StarRatingEditor
              productSlug={card.slug}
              stars={card.expertReviewRating}
            />
          </div>
          <div className="-mt-0.5 lg:mt-0">
            <LinkToRedirect
              externalId={'1'}
              linkParams={card.applyNowLinkParameters}
              orderId={'1'}
              query={undefined}
              slug={card.slug}
            >
              <div className="c-btn c-btn--primary max-w-none mb-2 lg:mb-3 py-2 / text-base md:text-sm lg:text-lg leading-snug shadow-lg">
                Apply Now
                <span className="c-btn__icon / inline-block w-4 ml-2">
                  <Image
                    width={16}
                    height={16}
                    alt="lock icon"
                    src="/static/icons/icon-lock-round-w.svg"
                    aria-hidden={true}
                    style={nextImageHelper.responsive}
                    sizes="100vw"
                  />
                </span>
              </div>
            </LinkToRedirect>
            <p className="text-center text-fs12 md:text-fs11 lg:text-fs12 xl:text-fs13 text-slate-500 leading-tight">
              <SecureSite
                issuerName={card.issuer.name}
                issuerSecureSite={card.issuerSecureSite}
              />
            </p>
            <div className="h-[1.5rem] / mb-1">
              {card.ratesAndFees && card.ratesAndFees && (
                <div className="w-full / text-center">
                  {card.ratesAndFees && card.ratesAndFees !== 'N/A' && (
                    <a
                      className="inline-block text-primary-bright text-fs12 leading-snug"
                      href={card.ratesAndFees}
                      onClick={() => {
                        clickRatesAndFees({
                          applyNowLink: card.applyNowLink,
                          component: 'Card Comparison Page Navigation Tile',
                          name: card.name,
                          url: window.location.href,
                        })
                      }}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Rates &amp; Fees
                    </a>
                  )}
                  {card.issuer.slug.current === 'american-express' && (
                    <p className="text-fs10 text-gray-600 / text-center / w-full leading-snug">
                      Terms Apply
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <ul className="c-card-carousel__list / text-fs13 lg:text-sm text-slate-700 leading-snug / border-t border-slate-200">
          {card.defaultFeatures.map((item: any) => {
            return (
              <li
                key={item._key}
                className="flex flex-col / py-2 lg:py-2.5 / border-b border-slate-200 / last:border-none"
              >
                <b className="text-primary">{item.Title}</b>
                <span>
                  <PortableText
                    value={item.Description}
                    components={{
                      block: {
                        normal: BlockRenderer as PortableTextBlockComponent,
                      },
                      types: {
                        cardAttribute: AttributeRenderer,
                      },
                    }}
                  />
                </span>
              </li>
            )
          })}
        </ul>
        <div className="absolute bottom-0 left-0 / w-full / px-4">
          <button
            tabIndex={0}
            onClick={() => setModalOpen(true)}
            className="flex w-full items-center justify-center / w-full / py-2.5 / text-center text-gray-600 text-fs13 / border-t border-slate-200 / hover:text-primary-bright focus:outline-none"
          >
            More Details
            <span className="relative / h-3 w-3 / ml-2 opacity-50">
              <Image
                alt="Expand Plus Icon"
                src="/static/icons/icon-plus-black.svg"
                fill
                sizes="100vw"
              />
            </span>
          </button>
        </div>
      </div>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <CardPanel
          onClose={() => setModalOpen(false)}
          view="modal"
          featured={false}
          entity={card}
          comparisonDisabled={true}
          categoryId={categoryId}
          externalId={categoryId}
          propsOrderId={card.slug}
        />
      </Modal>
    </div>
  )
}

function SkeletonCard() {
  return (
    <div className="flex flex-col items-center / w-full max-w-13 / p-4 lg:p-6 / bg-white / border border-slate-200 rounded">
      <div className="c-content-placeholder-bg / w-full max-w-[10rem] h-24 / mb-2 / bg-gray-200 rounded-sm">
        &nbsp;
      </div>
      <div className="c-content-placeholder-bg / w-full h-8 / mb-2 / bg-gray-200 rounded-sm">
        &nbsp;
      </div>
      <div className="c-content-placeholder-bg / w-44 h-6 / mb-2 / bg-gray-200 rounded-sm">
        &nbsp;
      </div>
      <div className="c-content-placeholder-bg / w-full h-20 / mb-2 / bg-gray-200 rounded-sm">
        &nbsp;
      </div>
      <div className="c-content-placeholder-bg / w-full h-20 / mb-2 / bg-gray-200 rounded-sm">
        &nbsp;
      </div>
      <div className="c-content-placeholder-bg / w-full h-20 / mb-2 / bg-gray-200 rounded-sm">
        &nbsp;
      </div>
      <div className="c-content-placeholder-bg / w-full h-8 / bg-gray-200 rounded-sm">
        &nbsp;
      </div>
    </div>
  )
}
