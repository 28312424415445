import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { AdvertiserDisclaimer } from '../../../components'

export const navOptionsBusinessHub = [
  {
    text: 'Top Credit Cards',
    value: 'best',
    href: '/credit-cards/business/best',
    slug: 'business_best',
  },
  {
    text: 'Cash Back',
    value: 'cash-back',
    href: '/credit-cards/business/cash-back',
    slug: 'business_cash-back',
  },
  {
    text: 'Travel',
    value: 'travel',
    href: '/credit-cards/business/travel',
    slug: 'business_travel',
  },
  {
    text: '0% Intro APR',
    value: '0-intro-apr',
    href: '/credit-cards/business/0-intro-apr',
    slug: 'business_0-intro-apr',
  },
  {
    text: 'No Annual Fee',
    value: 'no-annual-fee',
    href: '/credit-cards/business/no-annual-fee',
    slug: 'business_no-annual-fee',
  },
]

export function BusinessCategories() {
  return (
    <>
      <div className="c-bcategory / relative / w-full-vw / mb-4 lg:mb-5 / pt-2 lg:pt-3 pb-1.5 md:pb-4">
        <div className="container-fluid relative z-2">
          <div className="relative z-2 / flex flex-col sm:flex-row">
            <h2 className="c-bcategory__title / order-2 sm:order-1 / w-full / mb-1.5 md:mb-2 / text-center sm:text-left c-bheader font-semibold font-sans text-shadow-base">
              Business Credit Card Categories
            </h2>
            <div className="order-1 sm:order-2 / w-full mb-2 / text-right">
              <AdvertiserDisclaimer colorLight={true} />
            </div>
          </div>

          <div className="c-bcategory__category / -mx-1.5 lg:-mx-2">
            <ul className="c-bcategory__list / flex flex-row flex-wrap justify-center mx-auto w-full">
              {navOptionsBusinessHub.map((item, i) => (
                <li
                  className="c-bcategory__items / w-1/2 sm:w-1/3 md:w-1/5 / mb-2.5 / px-1.5 lg:px-2 cursor-pointer"
                  key={i}
                >
                  <Link
                    href={item.href}
                    className={`c-bcategory__pill-btn / inline-block / w-full / text-center p-2 lg:py-1.5 / text-fs13 lg:text-sm font-semibold
                      / border border-transparent rounded-full shadow transition-all / bg-white shadow-lg
                      / hover:bg-primary-light-bg hover:text-primary-mid hover:border-primary-bright hover:shadow-xl hover:scale-[1.04]`}
                  >
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className="c-hero__bot / absolute bottom-0 z-1 / w-full h-full / bg-no-repeat overflow-hidden"
          role="presentation"
        >
          <div className="w-full / -mb-px">
            <div className="md:hidden">
              <Image
                className="absolute bottom-0 / h-6"
                alt="background mask"
                width={720}
                height={104}
                sizes="100vw"
                src="/static/images/bg-bot-mask-white-sm.png"
              />
              <Image
                className="absolute bottom-0 / w-full h-20 opacity-10"
                alt="background mask"
                width={720}
                height={154}
                sizes="100vw"
                src="/static/images/bg-bot-mask-white-sm-tall.png"
              />
            </div>
            <div className="hidden md:block">
              <Image
                className="absolute bottom-0 / h-4 lg:h-5"
                alt="background mask"
                width={2880}
                height={132}
                sizes="100vw"
                src="/static/images/bg-bot-mask-white-lg.png"
              />
              <Image
                className="absolute bottom-0 / w-full h-16 opacity-10"
                alt="background mask"
                width={1540}
                height={232}
                sizes="100vw"
                src="/static/images/bg-bot-mask-white-lg-tall.png"
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .c-bheader {
            --tw-text-opacity: 1;
            color: #8fd0fd;
          }
          .c-bcategory {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            background-image: url('/static/images/bg-business-category-header.jpg');
            background-size: cover;
            background-repeat: no-repeat;
          }
        `}
      </style>
    </>
  )
}
