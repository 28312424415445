import React from 'react'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'
import cn from 'utils/classnames'
import { getViewsBySlug, getViewBySku } from '../../fetch-data/utils/pageViews'

export const Trending = (props: {
  value: {
    title?: string
    content?: string
    number?: string
    productSku?: string
    minimumViews?: number
    theme?: string
  }
}) => {
  const { title, content, number, productSku, minimumViews, theme } =
    props.value
  let views
  if (productSku && minimumViews) {
    views = getViewBySku(productSku)
    if (!views || views < minimumViews) return null
  } else {
    views = getViewsBySlug()
  }
  if (!views && !number) return null // if there are no views and no number, don't render
  const nf = new Intl.NumberFormat('en-US')
  const viewCount = number ? nf.format(parseInt(number)) : nf.format(views)

  return (
    <div className={cn('c-trending__container / mb-2')}>
      <div
        className={cn(
          'c-trending / max-w-[28rem] / mx-auto / px-3 py-2 / leading-tight text-sm / leading-snug rounded',
          theme == 'lightOrange'
            ? 'c-trending--light-orange'
            : 'c-trending--light-blue bg-primary-light-bg',
        )}
      >
        <div className="c-trending__inner / flex flex-row items-center justify-center">
          <span className="c-trending__icon / shrink-0 inline-block w-14">
            {theme == 'lightOrange' ? (
              <Image
                src="/static/icons/icon-bar-graph-up-right-tertiary.svg"
                alt="trending bar graph"
                width={48}
                height={48}
                style={nextImageHelper.responsive}
                aria-hidden={true}
              />
            ) : (
              <Image
                src="/static/icons/icon-bar-graph-up-right-primary-bright.svg"
                alt="trending bar graph"
                width={48}
                height={48}
                style={nextImageHelper.responsive}
                aria-hidden={true}
              />
            )}
          </span>
          <div className="c-trending__body / pl-2 pr-1">
            <div className="c-trending__title / font-bold uppercase tracking-wide text-primary-bright">
              {title ? `${title}` : 'Trending'}
            </div>
            <div className="c-trending__content">
              <b className="c-trending__number / text-primary">{viewCount}</b>{' '}
              <span className="c-trending__text">
                {content
                  ? `${content}`
                  : 'People Have Viewed this Offer in the Last Week'}
              </span>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .c-trending--light-orange {
          background-color: #fff6dd;
        }
        .c-trending--light-orange .c-trending__title {
          color: #ebac00;
        }
      `}</style>
    </div>
  )
}
