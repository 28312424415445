import * as React from 'react'
import Image from 'next/image'
import { v4 as uuid } from 'uuid'

import {
  AdvertiserDisclaimer,
  CardArt,
  LinkToRedirect,
  StarRatingEditor,
  LabelAd,
} from '../..'
import { CompareCredit } from '../../../../types/compare-credit'
import { nextImageHelper, supify } from '../../../utils'
import { SecureSite } from '../../secure-site'

export function HeroHpto(props: {
  cards: CompareCredit.FormattedCard[]
  headline: string
}) {
  const categoryId = '53'
  const externalId = '53'
  const [orderId, setOrderId] = React.useState<string | null>(null)
  const profileId = 'hpto'

  React.useEffect(() => {
    const id = uuid()
    setOrderId(id)
  }, [])

  return (
    <div
      id="hero"
      className="c-hero c-hero--disclosure-mix / -mb-8 / -mt-16 lg:-mt-20 lg:-mb-14 / pt-20 lg:pt-28 pb-12 / relative z-10"
    >
      <div className="c-hero__content c-hero__content-1 z-2 / container-fluid relative / lg:px-12 xl:px-16 lg:pt-8 / leading-tight">
        <div className="relative z-10 / md:flex md:flex-row / mb-2 lg:mb-3 mx-auto">
          <div className="md:w-3/5 lg:w-2/3 sm:pt-6 md:pt-8 lg:pt-4">
            <h1
              className="c-hero__title relative z-2 / mb-2 lg:mb-4 / 
            text-center md:text-left font-[800] text-[54px] sm:text-[56px] md:text-[68px] lg:text-[94px] xl:text-[104px] / leading-[1]"
            >
              <span className="text-tetriary">Earn 3%</span>
              <span className="block sm:inline-block md:block / sm:ml-3 md:ml-0 / text-white">
                Cash Back
              </span>
            </h1>
            <p
              className="c-hero__subtitle relative z-2 / md:mb-6 lg:mb-8 / text-white text-center md:text-left / 
            text-lg xs:text-[22px] sm:text-[28px] md:text-[24px] lg:text-[30px] xl:text-[32px]"
            >
              When You Choose Online Shopping
            </p>
            <div className="w-full max-w-[20rem] lg:max-w-[24rem] text-left / hidden md:block">
              <LinkToRedirect
                externalId={externalId}
                linkParams={props.cards[0].applyNowLinkParameters}
                orderId={orderId}
                profileId={profileId}
                query={undefined}
                slug={`${props.cards[0].slug}`}
              >
                <div className="c-btn c-btn--primary / w-full max-w-none / text-2xl / ml-0 mb-3 / py-2.5">
                  Apply Now
                  <span className="ml-2">
                    <div className="w-6 h-6">
                      <Image
                        alt="lock"
                        src="/static/icons/icon-lock-round-w.svg"
                        height={20}
                        width={20}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                        aria-hidden={true}
                      />
                    </div>
                  </span>
                </div>
              </LinkToRedirect>
              <p className="text-xs text-gray-500 text-center / mb-2">
                <SecureSite
                  issuerName={props.cards[0].issuer.name}
                  issuerSecureSite={props.cards[0].issuerSecureSite}
                />
              </p>
              <p className="text-xs text-gray-500 text-center / mb-4">
                Must apply here. Offers vary elsewhere.
              </p>
              {props.cards[0].ratesAndFees && props.cards[0].ratesAndFees && (
                <div className="w-full / mt-2 / text-center text-shadow">
                  {props.cards[0].ratesAndFees &&
                    props.cards[0].ratesAndFees !== 'N/A' && (
                      <p className="mb-0.5">
                        <a
                          className="inline-block text-primary-bright text-fs12 leading-snug / hover:text-primary-mid"
                          href={props.cards[0].ratesAndFees}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Rates &amp; Fees
                        </a>
                      </p>
                    )}
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:w-2/5 lg:w-1/3 / mt-10 sm:mt-12 md:pr-4">
            <div className="c-card relative / flex flex-col items-center justify-center / w-full mx-auto / mb-2">
              <div className="c-card-art__container c-card--tilt relative z-2 transition-all / relative top-[2rem] left-[2rem] / w-full max-w-13rem sm:max-w-[16rem] lg:max-w-full / mb-10 lg:right-[2rem] mx-auto">
                <div className="c-card-art / relative / mx-auto / transition-25s">
                  <div className="c-card-art__container / relative z-2 / w-full max-w-13rem xs:w-full xs:max-w-xs mx-auto / rounded-lg shadow overflow-hidden text-center">
                    <LinkToRedirect
                      externalId={externalId}
                      linkParams={props.cards[0].applyNowLinkParameters}
                      orderId={orderId}
                      profileId={profileId}
                      query={undefined}
                      slug={`${props.cards[0].slug}`}
                    >
                      <div className="relative flex overflow-hidden rounded-lg shadow z-0">
                        <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white lg:text-lg">
                          <span className="w-full xs:mb-1 lg:mb-2">
                            <div className="inline-block h-8 w-8 / lg:h-10 lg:w-10">
                              <Image
                                alt="lock"
                                src="/static/icons/icon-lock-round-w.svg"
                                height={40}
                                width={40}
                                style={nextImageHelper.responsive}
                                sizes="100vw"
                              />
                            </div>
                          </span>
                          <span className="w-full font-semibold">
                            Apply Now
                          </span>
                        </span>
                        <CardArt
                          _rev={props.cards[0]._rev}
                          cardArt={props.cards[0].cardArt}
                          imgClasses="relative z-0 w-full h-full object-cover top-0"
                          customCodeSnippets={props.cards[0].customCodeSnippets}
                          issuer={props.cards[0].issuer.slug.current}
                          name={props.cards[0].name}
                          orderId={orderId}
                          slug={props.cards[0].slug}
                          categoryId={categoryId}
                          externalId={externalId}
                          imagePriority={true}
                        />
                      </div>
                    </LinkToRedirect>
                  </div>
                </div>
              </div>
              <div
                className="c-card-shadow / absolute z-1 left-0 right-0 / w-full h-8 / mx-auto / rounded transition-all"
                role="presentation"
              >
                &nbsp;
              </div>
            </div>
            <div className="flex flex-col items-center justify-center / w-full mx-auto">
              <p className="max-w-sm / w-full mx-auto mb-2 / text-center leading-snug">
                <LinkToRedirect
                  externalId={externalId}
                  linkParams={props.cards[0].applyNowLinkParameters}
                  orderId={orderId}
                  profileId={profileId}
                  query={undefined}
                  slug={`${props.cards[0].slug}`}
                >
                  <span
                    className="text-white font-bold text-lg md:text-sm lg:text-base / hover:text-primary-light"
                    dangerouslySetInnerHTML={{
                      __html: supify(props.cards[0].name),
                    }}
                  />
                </LinkToRedirect>
              </p>
              <StarRatingEditor
                productSlug={props.cards[0].slug}
                stars={props.cards[0].expertReviewRating}
              />
            </div>
            <div className="w-full / md:hidden">
              <LinkToRedirect
                externalId={externalId}
                linkParams={props.cards[0].applyNowLinkParameters}
                orderId={orderId}
                profileId={profileId}
                query={undefined}
                slug={`${props.cards[0].slug}`}
              >
                <div className="c-btn c-btn--primary / max-w-[22rem] w-full / md:w-11/12 lg:w-64 / text-xl md:text-base lg:text-lg / mb-3 lg:mt-0.5 / py-2.5">
                  Apply Now
                  <span className="ml-2">
                    <div className="w-5 h-5">
                      <Image
                        alt="lock"
                        src="/static/icons/icon-lock-round-w.svg"
                        height={20}
                        width={20}
                        style={nextImageHelper.responsive}
                        sizes="100vw"
                        aria-hidden={true}
                      />
                    </div>
                  </span>
                </div>
              </LinkToRedirect>
              <p className="text-xs text-gray-500 text-center / mb-2">
                <SecureSite
                  issuerName={props.cards[0].issuer.name}
                  issuerSecureSite={props.cards[0].issuerSecureSite}
                />
              </p>
              <p className="text-xs text-gray-500 text-center / mb-4">
                Must apply here. Offers vary elsewhere.
              </p>
              {props.cards[0].ratesAndFees && props.cards[0].ratesAndFees && (
                <div className="w-full / mt-2 / text-center text-shadow">
                  {props.cards[0].ratesAndFees &&
                    props.cards[0].ratesAndFees !== 'N/A' && (
                      <p className="mb-0.5">
                        <a
                          className="inline-block text-primary-bright text-fs12 leading-snug / hover:text-primary-mid"
                          href={props.cards[0].ratesAndFees}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Rates &amp; Fees
                        </a>
                      </p>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="c-hero__content-2 / flex flex-col md:flex-row justify-center items-start / xxs:mb-6 md:mb-0">
          <div className="w-full / md:px-0 / text-gray-700 text-sm sm:text-base md:text-sm xl:text-base leading-snug lg:leading-normal">
            <div className="max-w-lg md:max-w-none mx-auto mb-4 md:mb-2">
              <ul className="md:grid md:grid-cols-3 md:gap-3 lg:gap-4 xl:gap-5 / w-full">
                <li className="flex flex-row items-center md:items-start lg:items-center / mb-3 / py-3 xs:py-4 / px-4 lg:px-3 xl:px-4 xl:pb-5 / bg-white rounded shadow-lg">
                  <span className="shrink-0 / inline-block w-10 xs:w-12 md:w-8 lg:w-14 xl:w-[4rem] / mr-2 sm:mr-3 lg:mr-2 xl:mr-3">
                    <Image
                      alt="wallet"
                      src="/static/icons/wallet-coins.svg"
                      height={48}
                      width={48}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                      aria-hidden={true}
                    />
                  </span>
                  <div className="w-full / pl-2 md:pl-1">
                    Turn your Online Shopping into cash back with{' '}
                    <span className="text-bofa-red font-bold">
                      3% cash back
                    </span>
                    {` `}
                    in the category of your choice.
                  </div>
                </li>
                <li className="flex flex-row items-center md:items-start lg:items-center / mb-3 / py-3 xs:py-4 / px-4 lg:px-3 xl:px-4 xl:pb-5 / bg-white rounded shadow-lg">
                  <span className="shrink-0 / inline-block w-10 xs:w-12 md:w-8 lg:w-14 xl:w-[4rem] / mr-2 sm:mr-3 lg:mr-2 xl:mr-3">
                    <Image
                      alt="bonus stars"
                      src="/static/icons/bonus-stars.svg"
                      height={48}
                      width={48}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                      aria-hidden={true}
                    />
                  </span>
                  <div className="w-full / pl-2 md:pl-1">
                    <span className="text-bofa-red font-bold">
                      $200 online cash rewards
                    </span>{' '}
                    bonus after you make at least $1,000 in purchases in the
                    first 90 days of account opening.
                  </div>
                </li>
                <li className="flex flex-row items-center md:items-start lg:items-center / mb-3 / py-3 xs:py-4 / px-4 lg:px-3 xl:px-4 xl:pb-5 / bg-white rounded shadow-lg">
                  <span className="shrink-0 / inline-block w-10 xs:w-12 md:w-8 lg:w-14 xl:w-[4rem] / mr-2 sm:mr-3 lg:mr-2 xl:mr-3">
                    <Image
                      alt="gold coin"
                      src="/static/icons/coin-gold.svg"
                      height={48}
                      width={48}
                      style={nextImageHelper.responsive}
                      sizes="100vw"
                      aria-hidden={true}
                    />
                  </span>
                  <div className="w-full / pl-2 md:pl-1">
                    <span className="text-bofa-red font-bold">
                      No annual fee
                    </span>{' '}
                    and cash rewards don’t expire as long as your account
                    remains open.
                  </div>
                </li>
              </ul>
            </div>

            <div className="flex justify-between / w-full max-w-lg md:max-w-none / mx-auto / leading-[1.2] ">
              <AdvertiserDisclaimer
                alignPopup="t-disclosure-left"
                colorLight={true}
              />
              <LabelAd theme="light-mix" />
            </div>
          </div>
        </div>
      </div>

      <div className="absolute z-1 / bottom-0 left-0 / w-full h-full / overflow-hidden">
        <div className="c-hero__bg-shape"></div>
      </div>
      <style jsx>{`
        /* ===== GENERAL HPTO CLASSES ===== */
        .text-bofa-red {
          color: #db2645;
        }

        /* ===== SHADOW ===== */
        .c-card-shadow {
          filter: blur(3px);
          left: 0rem;
          bottom: 0.5rem;
          width: 120px;
          height: 100px;
          transform-origin: bottom left;
          background: linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0.6) 100%
          );
          transform: rotate(-35deg) skewX(30deg) skewY(22deg) scale(1.2);
        }
        .c-card:hover .c-card-shadow {
          transform: rotate(-35deg) skewX(30deg) skewY(22deg) scale(1.15);
          opacity: 0.7;
        }

        @media (min-width: 640px) {
          .c-card-shadow {
            left: -4.5rem;
            bottom: 0.5rem;
            width: 110px;
            height: 90px;
            transform: rotate(-33deg) skewX(30deg) skewY(22deg) scale(1.6);
          }
          .c-card:hover .c-card-shadow {
            transform: rotate(-33deg) skewX(30deg) skewY(22deg) scale(1.5);
          }
        }
        @media (min-width: 1024px) {
          .c-card-shadow {
            left: -7rem;
            bottom: 0;
            transform: rotate(-33deg) skewX(30deg) skewY(22deg) scale(2);
          }
          .c-card:hover .c-card-shadow {
            transform: rotate(-33deg) skewX(30deg) skewY(22deg) scale(1.9);
          }
        }
        @media (min-width: 1152px) {
          .c-card-shadow {
            left: -8rem;
            bottom: 0.5rem;
            transform: rotate(-33deg) skewX(30deg) skewY(22deg) scale(2.2);
          }
          .c-card:hover .c-card-shadow {
            transform: rotate(-33deg) skewX(30deg) skewY(22deg) scale(2.1);
          }
        }

        /* ===== TILT ===== */
        .c-card--tilt {
          transform-origin: bottom left;
          transform: rotate(-15deg) skewX(20deg) skewY(-5deg) scaleX(1.1);
        }
        .c-card:hover .c-card--tilt {
          transform: rotate(-15deg) skewX(20deg) skewY(-5deg) scaleX(1.15)
            scaleY(1.05) translateY(-1rem);
        }

        @media (min-width: 1024px) {
          .c-card--tilt {
            transform: rotate(-15deg) skewX(20deg) skewY(-5deg) scaleX(1.2)
              scaleY(1.1);
          }
          .c-card:hover .c-card--tilt {
            transform: rotate(-15deg) skewX(20deg) skewY(-5deg) scaleX(1.25)
              scaleY(1.15) translateY(-1.5rem);
          }
        }

        /* ===== HERO ===== */
        .c-hero {
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
        }

        .c-hero__content {
          z-index: 3;
        }

        .c-hero__content-1,
        .c-hero__content-2 {
          transform: translateY(2rem);
        }

        .c-hero__content-1,
        .c-hero__content-2 {
          animation-name: animateIn;
          animation-duration: 0.75s;
          animation-fill-mode: forwards;
          opacity: 0;
        }
        .c-hero__content-2 {
          animation-delay: 0.25s;
        }
        .c-hero__highlight {
          transition: color 1.5s linear;
          animation-name: clrHighlight;
          animation-duration: 1.5s;
          animation-fill-mode: forwards;
        }

        @media (min-width: 1024px) {
          .c-hero {
            padding-bottom: 5rem;
          }
        }

        @media (min-width: 1152px) {
          .container-fluid {
            padding-left: 2rem;
            padding-right: 2rem;
          }
        }

        /*===== HERO BOTTOM =====*/
        .c-hero__bg-shape {
          position: absolute;
          bottom: 0;
          height: 100%;
          width: 100%;
          background-image: url(/static/images/bg-gradient-customized--m.jpg);
          background-repeat: no-repeat;
          background-position: top left;
          background-size: 105% 100%;
          z-index: 1;
        }

        @media (min-width: 768px) {
          .c-hero__bg-shape {
            background-image: url(/static/images/bg-gradient-customized--md.jpg);
            background-size: 180% 100%;
            background-position: left 10% top;
          }
        }
        @media (min-width: 1024px) {
          .c-hero__bg-shape {
            background-size: 130% 98%;
            background-position: left 25% top;
            background-image: url(/static/images/bg-gradient-customized--lg.jpg);
          }
        }
        @media (min-width: 1152px) {
          .c-hero__bg-shape {
            background-size: 140% 98%;
            background-position: left 30% top;
          }
        }
        @media (min-width: 1280px) {
          .c-hero__bg-shape {
            background-size: 130% 98%;
            background-position: left 23% top;
          }
        }
        @media (min-width: 1600px) {
          .c-hero__bg-shape {
            background-size: 100% 98%;
            background-position: left top;
          }
        }

        /*===== Animation =====*/
        @keyframes animateIn {
          from {
            opacity: 0;
            transform: translateY(2rem);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        @keyframes clrHighlight {
          0% {
            color: #052f5e;
          }
          50% {
            color: #21a3fc;
          }
          100% {
            color: #052f5e;
          }
        }
        @keyframes scaleUp {
          0% {
            transform: scale(0.95);
          }
          50% {
            transform: scale(1.05);
          }
          100% {
            transform: scale(1);
          }
        }
      `}</style>
    </div>
  )
}
