import Image from 'next/image'
import React from 'react'
import last from 'lodash/fp/last'
import { useRouter } from 'next/router'
import { PortableText, PortableTextBlockComponent } from '@portabletext/react'

import { nextImageHelper } from '../../utils'
import { trackUserInteraction } from '../../clients/segment'

export default function CategoryAlert(props: {
  alert: {
    message: any[] | undefined
    setHeight: React.Dispatch<React.SetStateAction<number>>
    show: boolean
    setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
    setUserClosedAlert: React.Dispatch<React.SetStateAction<boolean>>
  }
}) {
  const { alert } = props
  const router = useRouter()

  const BlockRender = ({ children }: { children: any }) => <p>{children}</p>

  const TrackableLink = ({
    value,
  }: {
    value: {
      _key: string
      _type: 'trackableLink'
      linkText: string
      linkUrl: string
    }
  }) => {
    return (
      <a
        className="underline / hover:text-primary-bright"
        href={value.linkUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          trackUserInteraction({
            key: value._key,
            href: value.linkUrl,
            text: value.linkText,
            type: 'trackable-link',
            url: router.asPath,
          })
        }
      >
        {value.linkText}
      </a>
    )
  }

  return (
    <div
      id="category-alert"
      className={`c-site-alert js-site-alert transition-25s / ${
        alert.show && alert.message ? 'is-shown' : 'is-hidden'
      } w-full`}
    >
      <div className="flex items-start justify-between md:justify-center / px-1 py-2 xs:py-2 / text-xs text-primary text-left md:text-center md:text-sm / bg-primary-light-bg / w-full">
        <div className="c-site-alert__content / flex items-start sm:items-center">
          <div className="inline-block / w-6 mr-2 / shrink-0">
            <Image
              src="/static/icons/icon-alert-circle-tetriary.svg"
              height={24}
              width={24}
              alt="alert icon"
              role="presentation"
              style={nextImageHelper.responsive}
              sizes="100vw"
            />
          </div>
          {alert.message && (
            <PortableText
              value={last(alert.message)}
              components={{
                block: BlockRender as PortableTextBlockComponent,
                types: {
                  trackableLink: TrackableLink,
                },
                marks: {
                  strong: ({ children }: { children: any }) => (
                    <b>{children}</b>
                  ),
                },
              }}
            />
          )}
        </div>
        <button
          className={`md:absolute md:top-0 md:right-0 md:mt-2 md:mr-2 / shrink-0 ${
            !alert.show ? 'hidden' : ''
          }`}
          name="Close Alert"
          type="button"
          aria-label="Close"
          onClick={() => {
            alert.setShowAlert(false)
            alert.setUserClosedAlert(true)
            alert.setHeight(0)
          }}
        >
          <div className="inline-block w-6 / opacity-50 hover:opacity-75 transition-all">
            <Image
              src="/static/icons/icon-close-primary-mid.svg"
              height={24}
              width={24}
              alt="close icon"
              role="presentation"
              style={nextImageHelper.responsive}
              sizes="100vw"
            />
          </div>
        </button>
      </div>
    </div>
  )
}
