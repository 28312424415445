import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { TabletAndDesktopFilter } from './tablet-desktop'
import { MobileFilter } from './mobile'
import { CategoryDescription, FadeIn, TrustCol } from '../../'
import { autoScroll } from '../../../utils'
import { CompareCredit } from '../../../../types'
import { clickCategoryTag } from '../../../clients/segment'
import { useStatsigClient } from '@statsig/react-bindings'

export interface Tag {
  icon: string
  range?: string
  id: string
  label: string
}

interface Props {
  categoryDescription: any[]
  /**
   * Maximum number of tags to show on desktop before collapsing the rest into a "More" button
   */
  desktopMax?: number
  tags: Tag[]
  /** The list of items to display and filter */
  render: (selectedTag: string) => React.ReactNode
  referencedCards: CompareCredit.Entities[]
  isCreditScoreFilter?: boolean
}

export function ByTag(props: Props) {
  const [selectedTag, setSelectedTag] = React.useState(props.tags[0].id)
  const [desktopDropdownItemSelected, setDesktopDropdownItemSelected] =
    React.useState(false)
  const { logEvent } = useStatsigClient()

  const maxShown = props.desktopMax || props.tags.length

  /*************************************************
   * Filter logic all (mobile, tablet, and desktop)
   */
  const handleFilter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    idx: number,
  ) => {
    logEvent('product_filter_selected', event.currentTarget.value)
    setSelectedTag(event.currentTarget.value)
    clickCategoryTag(event.currentTarget.value, 'Best Credit Cards')
    setDesktopDropdownItemSelected(idx >= maxShown)

    // Update the url without triggering a page refresh when using the filter in
    // the /credit-cards/best page, so that EXP-282 can be implemented
    // using the url to determine the label.
    if (location.pathname.includes('credit-cards/best/')) {
      if (event.currentTarget.value === 'best') {
        window.history.replaceState('', '', `/credit-cards/best/`)
      } else {
        window.history.replaceState(
          '',
          '',
          `/credit-cards/best/${event.currentTarget.value}/`,
        )
      }
    }

    if (isMobileOnly) {
      const navbarContainer = document.getElementById('navbar-container')
      const filterContainer = document.getElementById('filter-container')
      if (filterContainer && navbarContainer) {
        const yOffset = navbarContainer.offsetHeight * -1
        autoScroll(filterContainer, yOffset)
      }
    }
  }

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const category = params.get('category')
    if (
      !!category &&
      category.length > 0 &&
      !!props.tags.find(({ id }) => id === category)
    ) {
      setSelectedTag(category)
    }
  }, [])

  return (
    <div id="filter-container" className="w-full lg:flex lg:flex-wrap">
      <div className="w-full">
        <div
          id="filter-cards-category"
          className="relative / w-full lg:order-1 / mb-6"
        >
          <TabletAndDesktopFilter
            dropdownItemSelected={desktopDropdownItemSelected}
            handleFilter={handleFilter}
            maxShown={maxShown}
            tags={props.tags}
            selectedTag={selectedTag}
            isCreditScoreFilter={props.isCreditScoreFilter}
          />
          <MobileFilter
            handleFilter={handleFilter}
            tags={props.tags}
            selectedTag={selectedTag}
            isCreditScoreFilter={props.isCreditScoreFilter}
          />
        </div>
      </div>

      {/* render "children" i.e. list of items to display/filter */}
      <div className="lg:w-4/5 xl:w-9/12 / lg:order-3 / z-10">
        <FadeIn key={selectedTag}>{props.render(selectedTag)}</FadeIn>
        {props.categoryDescription?.length > 0 && (
          <div className="pt-8 pb-10">
            <CategoryDescription
              referencedCards={props.referencedCards}
              description={props.categoryDescription}
            />
          </div>
        )}
      </div>
      <div className="hidden lg:flex / lg:w-1/5 xl:w-3/12 shrink-0 order-2 / mb-12 / pr-6 xl:pr-12">
        <aside className="w-full">
          <TrustCol />
        </aside>
      </div>
    </div>
  )
}
