import { getExperiment } from '../utils'
import Cookies from 'js-cookie'
import { ALLOC } from '../utils/sort/constants'
import * as Sentry from '@sentry/nextjs'
import Script from 'next/script'
import React from 'react'
import getUserAgentInfo from '../utils/getUserAgentInfo'
import { isbot } from 'isbot'
import { segmentMiddleware } from '../utils/segmentMiddleware'
import { ISSUER_BOT_LIST } from '../utils/issuer-bot-list'
import useNetworkState from '../utils/use-network-status'

function getRandomNumberBetween0And100() {
  const min = 0
  const max = 100
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function checkSample(samplePct: number) {
  const randomNumber = getRandomNumberBetween0And100()
  return randomNumber < samplePct
}

const isProd = process.env.DEPLOYMENT_TARGET === 'production'

export function buildSegmentScript() {
  let experiment,
    experimentCookie,
    data,
    anonymousID,
    userAgent,
    isBot,
    isBotIssuer,
    ga_client_id,
    network

  if (typeof window !== 'undefined') {
    const userIP = Cookies.get('user-ip')

    const isBotIssuerList = Object.keys(ISSUER_BOT_LIST)
    isBotIssuer = userIP && isBotIssuerList.includes(userIP)
    isBot = isbot(navigator.userAgent)

    if (isBot) {
      const isInSample = checkSample(5)
      if (isInSample) {
        Sentry.captureMessage('Bot detected')
      }
    }
    if (isBotIssuer && userIP) {
      //@ts-ignore
      const issueBotName = ISSUER_BOT_LIST[userIP]
      Sentry.captureMessage(`Issuer bot detected: ${issueBotName}`)
    }

    const impactAgentRexeg = /compatible;impact.com agent/

    if (impactAgentRexeg.test(navigator.userAgent)) {
      Sentry.captureMessage(`Issuer bot detected: impact.com agent`)
    }

    ga_client_id = Cookies.get('_ga')

    experiment = getExperiment()
    data = {
      band: experiment
        ? `${experiment.experiment_name}.${experiment.variation_id}`
        : 'baseline',
      experiment: experiment || null,
      version: '1.1',
    }
    experimentCookie = Cookies.get(ALLOC)

    userAgent = getUserAgentInfo()
    network = useNetworkState()

    // adding this to get a feel for how many users dont have the exp cookie set
    if (!experimentCookie) {
      if (!isProd) {
        console.log('Cookie not set on browser')
      }
      // Pausing sending to Sentry temporarily. Need to see if this is helpful...
      // const err = new Error('Cookie not set on browser')
      // Sentry.captureException(err)
    }

    anonymousID = Cookies.get('anonymousID') || 'not-found'
    if (anonymousID === 'not-found' && !isBot) {
      const sample = checkSample(10)
      if (sample) {
        if (!isProd) {
          console.log('AnonymousID not set on browser')
        }
        // Pausing sending to Sentry temporarily. Need to see if this is helpful...
        // const err = new Error('AnonymousID not set on browser')
        // Sentry.captureException(err)
      }
    }
  }
  return `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://data-cdn.comparecredit.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="LBwSQ9cyHLo0aTO0HECIXhebOQiVSmbg";;analytics.SNIPPET_VERSION="4.15.3";

  analytics.ready(() => {

    if("${anonymousID}" !== "not-found") {
     window.analytics.identify("${anonymousID}")
    }
  })

    if("${anonymousID}" !== "not-found") {
     analytics.setAnonymousId("${anonymousID}")
    }
    analytics.load("${process.env.SEGMENT_WRITE_KEY}");
    analytics.track("Traffic Allocated", ${JSON.stringify(data)})
    window.isBot = ${isBot || isBotIssuer}
    analytics.addSourceMiddleware(${segmentMiddleware});
    analytics.page(window.location.pathname + window.location.search, {
      pixel_ratio: window.devicePixelRatio,
      screen_height: window.screen.height,
      screen_width: window.screen.width,
      tz_offset: new Date().getTimezoneOffset(),
      userAgent: ${JSON.stringify(userAgent)},
      network: ${JSON.stringify(network)},
      ga_client_id: ${JSON.stringify(ga_client_id)},
    })
  }}();`
}

export function Segment() {
  return (
    <Script
      id="segment-script"
      dangerouslySetInnerHTML={{
        __html: buildSegmentScript(),
      }}
      onError={(e) => {
        Sentry.captureException(e)
      }}
      strategy="afterInteractive"
    />
  )
}
