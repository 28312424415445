import sortBy from 'lodash/sortBy'
import { sortResponseInterface } from '../types'

export function handleSort(
  entities: { cards: any[] },
  sortedEntities: sortResponseInterface,
) {
  const entitiesWithPosition = entities.cards.map((entity) => {
    return {
      ...entity,
      sortPosition: sortedEntities.entities[entity.slug as string].position,
    }
  })

  const sorted = sortBy(entitiesWithPosition, ['sortPosition'])

  return sorted
}
