import { useMemo } from 'react'
import { NavData } from './types'
import { getCurrentYear } from '../../utils'
import { useIsUserOnBrave } from 'utils/useIsUserOnBrave'

export const categoryItems = [
  {
    text: `Top Credit Cards ${getCurrentYear()}`,
    icon: 'top-cc',
    href: '/credit-cards/best',
    slug: 'best',
  },
  {
    text: 'Balance Transfer',
    icon: 'balance-transfer',
    href: '/credit-cards/best/balance-transfer',
    slug: 'balance-transfer',
  },
  {
    text: 'Low Interest',
    icon: 'low-interest',
    href: '/credit-cards/best/low-interest',
    slug: 'low-interest',
  },
  {
    text: 'No Annual Fee',
    icon: 'no-annual-fee',
    href: '/credit-cards/best/no-annual-fee',
    slug: 'no-annual-fee',
  },
  {
    text: 'Cash Back',
    icon: 'cash-back',
    href: '/credit-cards/best/cash-back',
    slug: 'cash-back',
  },
  {
    text: 'Rewards',
    icon: 'rewards',
    href: '/credit-cards/best/rewards',
    slug: 'rewards',
  },
  {
    text: 'Travel',
    icon: 'travel',
    href: '/credit-cards/best/travel',
    slug: 'travel',
  },
  {
    text: 'No Foreign Fees',
    icon: 'no-foreign-fees',
    href: '/credit-cards/best/no-foreign-transaction-fee',
    slug: 'no-foreign-transaction-fee',
  },
  {
    text: 'Gas',
    icon: 'gas',
    href: '/credit-cards/best/gas',
    slug: 'gas',
  },
  {
    text: 'Dining',
    icon: 'dining',
    href: '/credit-cards/best/dining',
    slug: 'dining',
  },
  {
    text: 'Student',
    icon: 'student',
    href: '/credit-cards/best/student',
    slug: 'student',
  },
  {
    text: 'Business',
    icon: 'business',
    href: '/credit-cards/business/best',
    slug: 'business',
  },
  // {
  //   text: 'Secured',
  //   icon: 'secured',
  //   href: '/credit-cards/[...cardListSlugs]',
  //   as: '/credit-cards/best/secured',
  //   slug: 'secured',
  // },
]

export const getIssuerItems = (userIsOnBrave: boolean) => [
  {
    text: 'Citibank',
    icon: 'citi',
    href: '/credit-cards/citibank',
  },
  {
    text: 'Capital One',
    icon: 'capital-one',
    href: '/credit-cards/capital-one',
  },
  {
    text: 'Chase',
    icon: 'chase',
    href: '/credit-cards/chase',
  },
  ...(userIsOnBrave
    ? []
    : [
        {
          text: 'Bank of America',
          icon: 'boa',
          href: '/credit-cards/bank-of-america',
        },
      ]),
  {
    text: 'Wells Fargo',
    icon: 'wells-fargo',
    href: '/credit-cards/wells-fargo',
  },
  {
    text: 'American Express',
    icon: 'american-express',
    href: '/credit-cards/american-express',
  },
  {
    text: 'Discover',
    icon: 'discover',
    href: '/credit-cards/discover',
  },
  {
    text: 'Visa',
    icon: 'visa',
    href: '/credit-cards/visa',
  },
  {
    text: 'Mastercard',
    icon: 'mastercard',
    href: '/credit-cards/mastercard',
  },
]

export const creditItems = [
  {
    text: 'Excellent',
    icon: 'credit-excellent',
    href: '/credit-cards/credit-range/excellent',
    slug: 'excellent',
  },
  {
    text: 'Good',
    icon: 'credit-good',
    href: '/credit-cards/credit-range/good',
    slug: 'good',
  },
  {
    text: 'Fair',
    icon: 'credit-fair',
    href: '/credit-cards/credit-range/fair',
    slug: 'fair',
  },
  {
    text: 'Poor',
    icon: 'credit-poor',
    href: '/credit-cards/credit-range/poor',
    slug: 'poor',
  },
  {
    text: 'Limited/No',
    icon: 'credit-limited',
    href: '/credit-cards/credit-range/limited',
    slug: 'limited',
  },
]

export const getNavData = (userIsOnBrave: boolean): NavData => ({
  'credit-cards': {
    text: 'Card Category',
    href: '/credit-cards/best',
    sections: {
      main: { text: null, items: categoryItems },
    },
    // sections: {
    //   category: { text: 'Category', items: categoryItems },
    //   issuer: { text: 'Issuer', items: issuerItems },
    //   'credit-quality': { text: 'Credit Quality', items: creditItems },
    // },
  },
  'card-issuer': {
    text: 'Card Issuer',
    href: '/credit-cards/citibank',
    sections: {
      main: { text: null, items: getIssuerItems(userIsOnBrave) },
    },
  },
  'credit-range': {
    text: 'Credit Range',
    href: '/credit-cards/credit-range/excellent',
    sections: {
      main: { text: null, items: creditItems },
    },
  },
})

export const useNavData = (): NavData => {
  const userIsOnBrave = useIsUserOnBrave()

  const navData = useMemo(() => getNavData(userIsOnBrave), [userIsOnBrave])

  return navData
}
