import React from 'react'
import { CompareCredit } from '../../../../types/compare-credit'
import { StarRatingEditor } from '../..'

interface ProductRatingProps {
  productTip: CompareCredit.FormattedCard
  fontSize: 'text-4xl' | 'text-5xl'
  classes?: string
  layoutSlim?: boolean
}

export default function ProductRating(props: ProductRatingProps) {
  const { classes = '', fontSize, productTip, layoutSlim } = props
  return (
    <div
      className={`c-card-tile__rating / flex w-full mb-1 ${classes} ${
        layoutSlim ? 'items-start' : 'items-center justify-center'
      }`}
    >
      {productTip._type === 'card' ? (
        <div className="-mb-1 lg:mb-0">
          <StarRatingEditor
            productSlug={productTip.slug}
            stars={productTip.expertReviewRating}
          />
        </div>
      ) : (
        <>
          <p
            className={`text-primary-mid ${fontSize} font-serif leading-none / mr-4`}
          >
            {productTip.expertReviewRating}
          </p>
          <div className="relative flex flex-col items-start / -mb-3 lg:mb-0">
            <p className="text-gray-500 text-xs">CompareCredit Rating</p>
            <StarRatingEditor
              productSlug={productTip.slug}
              stars={productTip.expertReviewRating}
            />
          </div>
        </>
      )}
    </div>
  )
}
