import React from 'react'
import propOr from 'lodash/fp/propOr'

import { CompareCredit } from '../../../../types/compare-credit'
import { fetchRelatedCards, useFetchCardsSlug } from '../../../utils'
import { CardTile } from './card-tile'

export function FrequentlyComparedWith(props: {
  allCardSlugs: string[]
  cardsByCategory: {
    [key: string]: { slug: string; _id: string }[]
  }
  comparisonCart: CompareCredit.FormattedCard[]
  updateCart: (
    action: 'add' | 'remove',
    cards?: CompareCredit.FormattedCard[],
  ) => void
}) {
  const { allCardSlugs, cardsByCategory, comparisonCart, updateCart } = props
  const [selectedCard] = React.useState(comparisonCart[0])

  const cardSlugs = fetchRelatedCards(
    selectedCard,
    2,
    allCardSlugs,
    cardsByCategory,
  )
  const { cards: cardSuggestions } = useFetchCardsSlug(cardSlugs)

  const handleUpdateCart = (
    action: 'add' | 'remove',
    card?: CompareCredit.FormattedCard,
  ) => {
    updateCart(action, [card as CompareCredit.FormattedCard])
  }

  return (
    <section className="c-popular-comparisons / md:order-2 / -mx-4 xs:-mx-6 md:mx-0 / xs:mb-4 / md:px-1 pt-4 lg:pt-7 pb-4 / text-center">
      <h2 className="lg:mb-0.5 / text-xl md:text-lg lg:text-2xl text-primary font-bold text-center font-serif">
        Add another card to compare
      </h2>
      <p className="c-mid-line / relative inline-block / mb-2 md:mb-4 lg:mb-6 mx-auto / px-8 / text-center text-fs13 lg:text-sm text-gray-700 font-semibold uppercase">
        <span className="relative / inline-block / px-2 / bg-gray-100">
          Frequently Compared With
        </span>
      </p>

      <div className="c-results">
        <div className="c-results__cards / flex flex-col md:flex-row md:flex-col justify-center items-center justify-between / px-4 md:px-2 lg:px-4">
          {cardSuggestions.map((card) => {
            if (card._type !== 'card') return null
            const defaultListItems = [
              { label: 'Regular APR', value: card.aprPurchase },
              { label: 'Intro APR', value: card.aprIntroPurchase },
              { label: 'Intro BT APR', value: card.aprIntroBalanceTransfer },
            ]

            const overrideItem = card.featureOverrides.filter(
              (item) => item.categoryOverride === 'best',
            )

            const featuresToRender =
              overrideItem.length > 0
                ? overrideItem[0].features
                : propOr([], 'defaultFeatures', card).length > 0
                ? card.defaultFeatures
                : defaultListItems

            return (
              <CardTile
                key={(card as CompareCredit.FormattedCard).slug}
                card={card as CompareCredit.FormattedCard}
                handleUpdateCart={handleUpdateCart}
                listItems={featuresToRender}
                totalCardCount={cardSuggestions.length}
              />
            )
          })}
        </div>
      </div>
      <style jsx>{`
        .c-mid-line::before {
          position: absolute;
          left: 0;
          top: 45%;
          bottom: 0;
          display: inline-block;
          content: '';
          width: 100%;
          height: 1px;
          border-top: 1px solid #cccccc;
        }
      `}</style>
    </section>
  )
}
