/**
 * Takes a URL and replaces the domain with `example.com` if
 * `DEPLOYMENT_TARGET !== production`
 */

export function replaceDomain(
  url: string,
  isProd: boolean,
  hostURL?: URL,
): string {
  if (isProd) {
    return url
  } else {
    const isWindowUndefined = typeof window === 'undefined'

    let parsedUrl
    if (isWindowUndefined && hostURL) {
      parsedUrl = hostURL
    } else {
      parsedUrl = new URL(window.location.href)
    }
    const q = `applyNowURL=${url.replace('?', '&')}`

    return `${
      parsedUrl.origin
    }/testApplyNow?${q}&fullApplyNow=${encodeURIComponent(url)}`
  }
}
