import React from 'react'
import range from 'lodash/range'

export function CardSingleColSkeleton(props: {
  n: number
  highlightNumber: boolean
}) {
  const { n, highlightNumber } = props
  return (
    <div className="c-card-single-col-skeleton / flex flex-row md:flex-col / w-full max-w-lg md:max-w-none lg:w-auto / mb-6 mx-auto lg:-ml-14 lg:-mr-14">
      {range(n).map((idx: number) => (
        <div key={idx} className="flex items-stretch / shrink-0 / w-full">
          <div className="flex flex-col justify-between items-stretch / w-full / mb-1 / p-6 md:p-8 / rounded shadow bg-white">
            <div className="md:flex md:flex-row md:items-stretch md:flex-wrap">
              <div className="c-card-single-col-skeleton__header / max-w-xs / mx-auto md:w-1/3 md:pr-6">
                <div className="c-content-placeholder-bg / flex items-stretch / shrink-0 / w-full h-40 md:h-28 / mb-2 / rounded overflow-hidden transition-all"></div>
                <div className="c-content-placeholder-bg / w-full h-12 / mb-4 / rounded-sm"></div>
              </div>
              <div className="c-card-single-col-skeleton__col-1 / md:flex md:flex-col md:items-start md:justify-start / max-w-xs md:max-w-none md:w-2/3 / mx-auto">
                <div className="c-content-placeholder-bg / w-full md:w-72 h-10 / mb-1.5"></div>
                <div className="c-content-placeholder-bg / w-2/3 md:w-48 h-6 / mb-4 md:mb-1.5 mx-auto md:ml-0"></div>
                <div className="c-content-placeholder-bg / w-full h-16 / mb-4 / hidden md:block"></div>
              </div>
              <div className="c-card-single-col-skeleton__col-2 / md:flex md:flex-row / w-full">
                <div className="c-content-placeholder-bg / w-full md:w-1/3 h-16 md:h-32 / mb-1.5 md:mb-0 md:mr-1 / rounded-sm"></div>
                <div className="c-content-placeholder-bg / w-full md:w-1/3 h-16 md:h-32 / mb-1.5 md:mb-0 md:mx-1 / rounded-sm"></div>
                {!highlightNumber && (
                  <div className="c-content-placeholder-bg / w-full md:w-1/3 h-16 md:h-32 / mb-1.5 md:mb-0 md:mx-1 / rounded-sm"></div>
                )}
                <div className="c-content-placeholder-bg / w-full md:w-1/3 h-16 md:h-32 / md:ml-1 / rounded-sm"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <style jsx>{`
        .c-content-placeholder-bg {
          position: relative;
          overflow: hidden;
        }
        .c-content-placeholder-bg:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 70%;
          height: 100%;
          will-change: transform;
          background: -moz-linear-gradient(
            left,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          background: -webkit-linear-gradient(
            left,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          background: linear-gradient(
            to right,
            rgba(26, 32, 44, 0) 0%,
            rgba(26, 32, 44, 1) 50%,
            rgba(255, 255, 255, 0.3) 85%,
            rgba(255, 255, 255, 0) 100%
          );
          filter: unquote(
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a202c', endColorstr='#00ffffff',GradientType=1 )"
          );
          opacity: 0.04;
          animation: content-placeholder-animation 1.35s linear infinite;
        }
        @keyframes content-placeholder-animation {
          0% {
            transform: translateX(-80%);
          }
          100% {
            transform: translateX(200%);
          }
        }
      `}</style>
    </div>
  )
}
