import React from 'react'
import Image from 'next/image'
import { CompareCredit } from '../../../../types/compare-credit'
import { nextImageHelper, supify } from '../../../utils'

import { CardArt, LinkToRedirect, StarRatingEditor } from '../../'
import Link from 'next/link'

export function CardTile(props: {
  orderId: string | any
  card: CompareCredit.FormattedCard | CompareCredit.NonPaidCard
  categoryId: string
  externalId: string
  referencedCards: null | Record<string, CompareCredit.Entities>
  ribbonText?: string | null
  ribbonColor?: string | null
  theme?: string
}) {
  const {
    card,
    categoryId,
    externalId,
    orderId,
    ribbonText,
    ribbonColor,
    theme,
  } = props

  return (
    <div
      className={`c-compare-v-card / flex flex-col items-center justify-center / w-full ${theme}`}
    >
      {ribbonText ? (
        <div className="mb-1.5 text-center">
          <p
            className={`c-ribbon c-ribbon--slimmer /
                relative inline-block /
                w-auto px-2 py-1 /
                font-bold text-fs11 tracking-wide uppercase /
                ${
                  ribbonColor === 'gold'
                    ? 'bg-tetriary text-primary'
                    : 'c-ribbon--primary-bright bg-primary-bright text-white'
                }`}
          >
            {ribbonText}
          </p>
        </div>
      ) : (
        <div className="mb-1.5">&nbsp;</div>
      )}
      <div className={`w-full`}>
        <div className="c-card-art / text-center flex items-center justify-center / mb-1.5">
          <div className="c-card-art__container / relative / xs:w-2/3 md:w-3/5 lg:w-1/2 max-w-xs / rounded-sm shadow">
            {card._type === 'card' ? (
              <LinkToRedirect
                externalId={externalId}
                linkParams={card.applyNowLinkParameters}
                orderId={orderId}
                slug={card.slug}
              >
                <div className="relative flex w-full rounded-sm overflow-hidden">
                  <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-fs13 xs:text-sm md:text-base">
                    <span className="c-card-art__icon / w-full mb-0">
                      <span className="inline-block w-6 md:w-7 lg:w-10">
                        <Image
                          height={32}
                          width={32}
                          alt="icon lock"
                          src="/static/icons/icon-lock-round-w.svg"
                          style={nextImageHelper.responsive}
                          sizes="100vw"
                        />
                      </span>
                    </span>
                    <span className="w-full font-bold">Apply Now</span>
                  </span>
                  <CardArt
                    _rev={card._rev}
                    cardArt={card.cardArt}
                    imgClasses="relative z-0 w-full h-full object-cover top-0"
                    issuer={card.issuer.slug.current}
                    name={card.name}
                    orderId={orderId}
                    slug={card.slug}
                    categoryId={categoryId}
                    externalId={externalId}
                  />
                </div>
              </LinkToRedirect>
            ) : (
              <Link href={card.moreInfoLink}>
                <div className="relative flex w-full rounded-sm overflow-hidden">
                  <span className="c-card-art__content / absolute / flex flex-wrap content-center items-center / z-10 left-0 / w-full h-full text-white text-fs13 xs:text-sm md:text-base">
                    <span className="w-full font-bold">Learn More</span>
                  </span>
                  <CardArt
                    _rev={card._rev}
                    cardArt={card.cardArt}
                    imgClasses="relative z-0 w-full h-full object-cover top-0"
                    issuer={card.issuer.slug.current}
                    name={card.name}
                    orderId={orderId}
                    slug={card.slug}
                    categoryId={categoryId}
                    externalId={externalId}
                  />
                </div>
              </Link>
            )}
          </div>
        </div>
        <h2 className="c-compare-v-card__name / max-w-xs / mx-auto / text-sm font-sans font-bold text-center leading-tight / mb-1.5">
          {card._type === 'card' ? (
            <LinkToRedirect
              externalId={externalId}
              linkParams={card.applyNowLinkParameters}
              orderId={orderId}
              slug={card.slug}
            >
              <div
                className="text-primary-mid hover:text-primary-bright"
                dangerouslySetInnerHTML={{ __html: supify(card.name) }}
              />
            </LinkToRedirect>
          ) : (
            <Link href={card.moreInfoLink}>
              <div
                className="text-primary-mid hover:text-primary-bright"
                dangerouslySetInnerHTML={{ __html: supify(card.name) }}
              />
            </Link>
          )}
        </h2>
      </div>
      <div className="c-compare-v-card__rating / flex justify-center w-full">
        <StarRatingEditor
          productSlug={card.slug}
          stars={card.expertReviewRating}
        />
      </div>

      <style jsx>{`
        .c-card-art__container {
          width: 12rem;
        }
        .c-compare-v-card__name {
          font-size: 16px;
        }
        @media (min-width: 480px) {
          .c-compare-v-card__name {
            font-size: 18px;
          }
        }
        @media (min-width: 480px) {
          .c-card-art__container {
            width: 100%;
          }
        }

        /*===== THEME =====*/

        .t-compare-v-2 .c-ribbon {
          padding-top: 0.4rem;
          font-size: 10px;
        }
        .t-compare-v-2 .c-compare-v-card__name,
        .t-compare-v-3 .c-compare-v-card__name {
          font-size: 13px;
        }

        @media (min-width: 480px) {
          .t-compare-v-2 .c-card-art__container,
          .t-compare-v-3 .c-card-art__container {
            width: 66.666666%;
          }
        }

        @media (min-width: 640px) {
          .t-compare-v-2 .c-compare-v-card__name {
            font-size: 16px;
          }
        }

        @media (min-width: 640px) {
          .t-compare-v-3 .c-compare-v-card__name {
            font-size: 14px;
          }
        }
        @media (min-width: 768px) {
          .t-compare-v-3 .c-compare-v-card__name {
            font-size: 16px;
          }
        }
      `}</style>
    </div>
  )
}
