import React from 'react'
import { FacebookIcon, FacebookShareButton } from 'react-share'

export function SocialShareButtons(props: {
  headline: string
  location: string
  seoTitle: string | undefined
}) {
  return (
    <>
      <FacebookShareButton url={props.location}>
        <FacebookIcon size={32} round path="" />
      </FacebookShareButton>
    </>
  )
}
