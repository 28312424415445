import React from 'react'
import { useFetchCardsSlug } from '../../utils'

export const RatesAndFeesLink = ({
  children,
  value,
}: {
  children: any
  value: { slug: string }
}) => {
  const cardsSlug = value.slug ? [value.slug] : []
  const {
    cards: [productTip],
  } = useFetchCardsSlug(cardsSlug)

  const ratesAndFees =
    productTip && productTip._type === 'card' && productTip.ratesAndFees

  return ratesAndFees && ratesAndFees !== 'N/A' ? (
    <a
      className="mt-8 mb-2 / text-primary-bright / hover:text-primary-mid"
      href={` ${ratesAndFees} `}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  ) : (
    <span>{children}</span>
  )
}
