import React, { useCallback } from 'react'
import Image from 'next/image'
import { nextImageHelper } from '../../utils'
import { VideoWrapper } from '../'
import {
  modalExtensionClicked,
  modalExtensionWatched,
} from '../../clients/segment'

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void
  }
}

export function ModalLeaveBehindExtension({
  onClose,
  open,
}: {
  onClose: () => void
  open?: boolean
}) {
  const videoId = 'VRP-lRRLKvs'

  const stopVideo = useCallback(() => {
    // @ts-ignore
    const ytPlayer = window.YT.get(videoId)
    if (ytPlayer) {
      ytPlayer.stopVideo()
    }
  }, [videoId])

  const handleModalClose = () => {
    stopVideo()
    onClose()
  }

  const handleCtaDownload = () => {
    modalExtensionClicked()
  }

  const handleVideoPlay = () => {
    modalExtensionWatched()
  }

  const onPlayerStateChange = (event: any) => {
    console.log('Extension onPlayerStateChange')
    // @ts-ignore
    if (event.data === YT.PlayerState.PLAYING) {
      handleVideoPlay()
      console.log('extension played')
    }
  }

  return (
    <div
      className={open ? 'block' : 'hidden'}
      aria-hidden={open ? 'false' : 'true'}
    >
      <div className="c-modal-lb-ext / fixed / flex items-center justify-center / w-full h-full top-0 left-0 / p-4 md:p-8 / overflow-scroll">
        <div className="c-modal-lb-ext__container / relative z-2 top-0 / max-w-[420px] md:max-w-[768px] w-full / mx-auto mb-6 / overflow-visible">
          <div
            className="c-modal-lb-ext__content / relative / flex-wrap flex flex-col sm:flex-row items-center justify-center sm:items-start /
            w-full / mx-auto / leading-normal rounded-xl shadow-xl"
            tabIndex={-1}
            role="dialog"
          >
            <button
              className="c-modal-lb-ext__btn-close / absolute z-3 -top-[32px] -left-[16px] xs:-left-[24px] opacity-60 / w-12 h-12 / mt-2 mr-2 / p-1.5 / cursor-pointer / opacity-100 /
              bg-[rgba(33,163,252,.4)] / rounded-full / transition-all--25 / hover:opacity-75 hover:opacity-100"
              aria-label="close modal"
              type="button"
              onClick={() => handleModalClose()}
            >
              <span className="block w-full / p-1 / bg-white rounded-full">
                <Image
                  className="w-8"
                  height={16}
                  width={16}
                  alt="close"
                  role="presentation"
                  aria-hidden="true"
                  src="/static/icons/icon-close-primary-mid.svg"
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </span>
            </button>

            <div className="c-modal-lb-ext__graphic / absolute z-3 / -top-[37px] -right-[8px] xs:-right-[24px] lg:-right-[44px] sm:-top-[44px] sm:-right-[32px] lg:-top-[50px]">
              <span className="inline-block w-[120px] sm:w-[140px] lg:w-[160px]">
                <Image
                  className="w-full"
                  height={161}
                  width={194}
                  alt="man sitting"
                  role="presentation"
                  aria-hidden="true"
                  src="/static/illustrations/man-sitting-rewards-1.svg"
                  style={nextImageHelper.responsive}
                  sizes="100vw"
                />
              </span>
            </div>

            <div className="c-modal-lb-ext__inner / relative z-2 / flex flex-col justify-center md:flex-row md:items-center md:justify-center / w-full / rounded-xl">
              <div
                className="c-modal-lb-ext__col-1 relative / md:right-[-1rem] / md:order-2 / w-full md:w-1/2 lg:w-[50%] md:h-auto /
                shadow-xl / overflow-hidden rounded-b-xl md:rounded-lg"
              >
                <div className="c-video-wrapper">
                  <VideoWrapper
                    url={`https://www.youtube.com/embed/${videoId}?rel=0&si=ojjSERDBY98Rqq4G`}
                    title="Learn more about the extension"
                    videoId={videoId}
                    onPlayerStateChange={onPlayerStateChange}
                  />
                </div>
              </div>
              <div className="c-modal-lb-ext__col-2 / md:w-1/2 lg:w-[50%] / px-4 xs:px-8 md:pr-4 pb-6 pt-4 / xs:pt-3 / sm:pt-4 sm:pb-8 md:py-6 lg:py-8 / text-center md:text-left">
                <p className="mb-2 / text-3xl xs:text-[36px] md:text-[32px] md:text-[34px] lg:text-[36px] leading-tight text-primary-mid font-serif font-bold">
                  Let Trove do the <span className="block">Math for You</span>
                </p>
                <p className="mb-4 / text-base lg:text-lg text-slate-600">
                  We calculate the rewards you&apos;ll earn from a purchase and
                  highlight the best card to maximize your&nbsp;spending
                </p>
                <div className="flex flex-col justify-center max-w-[18rem] mx-auto md:ml-0">
                  <a
                    href="https://www.comparecredit.com/shopping"
                    target="_blank"
                    rel="noreferrer"
                    className="mb-3 / px-4 / c-btn c-btn--primary text-[20px] md:text-[18px] lg:text-[20px] / drop-shadow-lg hover:drop-shadow-xl"
                    onClick={() => handleCtaDownload()}
                  >
                    Add to Chrome. It&apos;s free!
                  </a>
                  <p className="text-center">
                    <button
                      className="text-slate-600 text-sm text-center font-semibold / hover:underline hover:text-primary-bright-light transition"
                      type="button"
                      onClick={() => handleModalClose()}
                    >
                      No Thanks
                    </button>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="c-modal-lb-ext-extension__shapes / absolute z-1 bottom-0 left-0 / flex / w-full h-full"
              role="presentation"
            />
          </div>
        </div>
        <button
          className={`c-modal-lb-ext__bg / fixed top-0 left-0 z-1 / w-full h-full`}
          type="button"
          aria-label="close modal"
          onClick={() => handleModalClose()}
        />
      </div>
      <style jsx>{`
        .c-modal-lb-ext {
          z-index: 81;
        }
        .c-modal-lb-ext__content {
          background: radial-gradient(
              73.42% 58.03% at 0% 0%,
              #d1e6ff 0%,
              rgba(233, 243, 255, 0) 100%
            ),
            linear-gradient(75deg, #daffca 0%, #bae7fe 100%);
        }
        .c-modal-lb-ext__content {
          background-color: #fff;
        }
        .c-modal-lb-ext__bg {
          background-color: rgba(2, 22, 45, 0.8);
        }
      `}</style>
    </div>
  )
}
